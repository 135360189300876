import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { supabase } from "@/integrations/supabase/client";
import { InviteForm } from "./auth/InviteForm";
import { useAuth } from "@/contexts/AuthContext";
import { useToast } from "@/hooks/use-toast";

export const InviteDialog = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [availableInvites, setAvailableInvites] = useState<number | null>(null);
  const { user, isLoading } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    const fetchInviteQuota = async () => {
      if (!user) return;

      try {
        const { data: profile, error } = await supabase
          .from('profiles')
          .select('available_invites, membership_tier')
          .eq('id', user.id)
          .single();

        if (error) throw error;

        console.log("[InviteDialog] Fetched invite quota:", {
          availableInvites: profile.available_invites,
          membershipTier: profile.membership_tier
        });

        setAvailableInvites(profile.available_invites);
      } catch (error) {
        console.error("[InviteDialog] Error fetching invite quota:", error);
        toast({
          title: "Error",
          description: "Failed to load invite quota. Please try again.",
          variant: "destructive",
        });
      }
    };

    fetchInviteQuota();
  }, [user, toast]);

  useEffect(() => {
    if (user) {
      console.log("[InviteDialog] User authenticated, redirecting to dashboard", {
        userId: user.id
      });
      setIsDialogOpen(false);
      navigate('/waitlist-dashboard');
    }
  }, [user, navigate]);

  const handleOpenChange = (open: boolean) => {
    console.log("[InviteDialog] Dialog state changing", { open });
    if (open && availableInvites === 0) {
      toast({
        title: "No invites available",
        description: "You've used all your available invites.",
        variant: "destructive",
      });
      return;
    }
    setIsDialogOpen(open);
  };

  if (isLoading || user) {
    return null;
  }

  return (
    <Dialog open={isDialogOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button variant="secondary" className="bg-zinc-800 text-white hover:bg-zinc-700">
          Join waitlist
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md bg-zinc-900 border-zinc-800">
        <DialogHeader>
          <DialogTitle className="text-white">Join the exclusive Jungle community</DialogTitle>
          <DialogDescription className="text-gray-400">
            Get early access to the future of creator management.
          </DialogDescription>
        </DialogHeader>
        <InviteForm 
          isLoading={isLoading}
          setIsLoading={() => {
            console.log("[InviteDialog] Form loading state changed");
          }}
          onSuccess={() => {
            console.log("[InviteDialog] Form submitted successfully, closing dialog");
            setIsDialogOpen(false);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
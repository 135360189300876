import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { ChartConfig, ChartContainer } from "@/components/ui/chart";
import { useChartData } from "./hooks/useChartData";
import { useTrendCalculation } from "./hooks/useTrendCalculation";
import { ChartHeader } from "./components/ChartHeader";
import { ChartContent } from "./components/ChartContent";
import { ChartFooter } from "./components/ChartFooter";

interface SignupData {
  date: string;
  count: number;
}

interface SignupsChartProps {
  data: SignupData[];
}

const chartConfig = {
  signups: {
    label: "Daily Signups",
    color: "hsl(var(--chart-1))",
  },
} satisfies ChartConfig;

export const SignupsChart = ({ data }: SignupsChartProps) => {
  const {
    dateRange,
    setDateRange,
    isCumulative,
    setIsCumulative,
    chartData
  } = useChartData(data);

  const { trend, trendText } = useTrendCalculation(data, dateRange);

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center">
          <div>
            <CardTitle>Daily Signups</CardTitle>
            <CardDescription>
              Showing signup trends over time
            </CardDescription>
          </div>
          <ChartHeader
            dateRange={dateRange}
            onDateRangeChange={setDateRange}
            isCumulative={isCumulative}
            onCumulativeChange={setIsCumulative}
          />
        </div>
      </CardHeader>
      <CardContent className="h-[400px]">
        <ChartContainer config={chartConfig}>
          <ChartContent data={chartData} />
        </ChartContainer>
      </CardContent>
      <CardFooter>
        <ChartFooter trend={trend} trendText={trendText} />
      </CardFooter>
    </Card>
  );
};
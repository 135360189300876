import { useState } from "react";
import { DateRange } from "react-day-picker";
import { addDays, isWithinInterval, startOfDay } from "date-fns";

interface SignupData {
  date: string;
  count: number;
}

export const useChartData = (rawData: SignupData[]) => {
  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: addDays(new Date(), -30),
    to: new Date(),
  });
  const [isCumulative, setIsCumulative] = useState(false);

  const filteredData = rawData
    .filter(item => {
      if (!dateRange?.from || !dateRange?.to) return true;
      const itemDate = startOfDay(new Date(item.date));
      return isWithinInterval(itemDate, {
        start: startOfDay(dateRange.from),
        end: startOfDay(dateRange.to),
      });
    })
    .map((item, index, array) => {
      const date = new Date(item.date).toLocaleDateString('en-US', { 
        month: 'short', 
        day: 'numeric' 
      });
      
      if (isCumulative) {
        const cumulativeCount = array
          .slice(0, index + 1)
          .reduce((sum, curr) => sum + curr.count, 0);
        return {
          date,
          signups: cumulativeCount
        };
      }
      
      return {
        date,
        signups: item.count
      };
    });

  return {
    dateRange,
    setDateRange,
    isCumulative,
    setIsCumulative,
    chartData: filteredData
  };
};
import { TrendingUp } from "lucide-react";
import { cn } from "@/lib/utils";

interface ChartFooterProps {
  trend: number;
  trendText: string;
}

export const ChartFooter = ({ trend, trendText }: ChartFooterProps) => {
  return (
    <div className="flex-col items-start gap-2 text-sm">
      <div className="flex items-center gap-2 font-medium leading-none">
        {trend === 0 ? "Trending steady" : `Trending ${trend >= 0 ? "up" : "down"}`} {trendText} compared to previous period
        <TrendingUp className={cn(
          "h-4 w-4",
          trend > 0 ? "text-green-500" : trend < 0 ? "text-red-500 rotate-180" : "text-yellow-500"
        )} />
      </div>
      <div className="leading-none text-muted-foreground">
        Comparing current period with previous period of equal length
      </div>
    </div>
  );
};
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";

interface AnalyticsData {
  totalUsers: number;
  waitlistUsers: number;
  normalUsers: number;
  premiumUsers: number;
  dailySignups: { date: string; count: number; }[];
}

export const useAnalyticsData = () => {
  const [data, setData] = useState<AnalyticsData | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchAnalytics();
  }, []);

  const fetchAnalytics = async () => {
    try {
      console.log("[useAnalyticsData] Fetching analytics data");
      
      // First get all admin users IDs to exclude them
      const { data: adminUsers } = await supabase
        .from('admin_users')
        .select('id');
      
      const adminIds = adminUsers?.map(admin => admin.id) || [];

      // Get total users excluding admins
      const { data: totalData } = await supabase
        .from('profiles')
        .select('count')
        .not('id', 'in', `(${adminIds.join(',')})`);

      // Get waitlist users excluding admins
      const { data: waitlistData } = await supabase
        .from('profiles')
        .select('count')
        .eq('membership_tier', 'waitlist')
        .not('id', 'in', `(${adminIds.join(',')})`);

      // Get normal users excluding admins
      const { data: normalData } = await supabase
        .from('profiles')
        .select('count')
        .eq('membership_tier', 'normal')
        .not('id', 'in', `(${adminIds.join(',')})`);

      // Get premium users excluding admins
      const { data: premiumData } = await supabase
        .from('profiles')
        .select('count')
        .eq('membership_tier', 'premium')
        .not('id', 'in', `(${adminIds.join(',')})`);
      
      // Get signups data for the chart
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      
      const { data: signupsData } = await supabase
        .from('profiles')
        .select('created_at')
        .not('id', 'in', `(${adminIds.join(',')})`)
        .gte('created_at', thirtyDaysAgo.toISOString())
        .order('created_at');

      const dailySignups = processSignupsData(signupsData || []);
      
      setData({
        totalUsers: totalData?.[0]?.count || 0,
        waitlistUsers: waitlistData?.[0]?.count || 0,
        normalUsers: normalData?.[0]?.count || 0,
        premiumUsers: premiumData?.[0]?.count || 0,
        dailySignups,
      });
    } catch (error) {
      console.error("[useAnalyticsData] Error fetching analytics:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const processSignupsData = (signups: any[]) => {
    const dailyCounts: { [key: string]: number } = {};
    const days = [];
    
    for (let i = 0; i < 30; i++) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      const dateStr = date.toISOString().split('T')[0];
      dailyCounts[dateStr] = 0;
      days.unshift(dateStr);
    }
    
    signups.forEach((signup) => {
      const date = new Date(signup.created_at).toISOString().split('T')[0];
      if (dailyCounts[date] !== undefined) {
        dailyCounts[date]++;
      }
    });
    
    return days.map((date) => ({
      date,
      count: dailyCounts[date],
    }));
  };

  return { data, isLoading };
};
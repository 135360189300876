import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { NotificationsButton } from "@/components/NotificationsButton";
import { LogOut, User } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { useAuth } from "@/contexts/AuthContext";

interface UserActionsProps {
  notifications: any[];
  showProfileButton?: boolean;
}

export const UserActions = ({ notifications, showProfileButton = true }: UserActionsProps) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { session } = useAuth();

  const handleSignOut = async () => {
    try {
      console.log("[UserActions] Initiating sign out", { hasSession: !!session });
      
      // If no session, just redirect to login
      if (!session) {
        console.log("[UserActions] No active session found, redirecting to login");
        navigate('/login', { replace: true });
        return;
      }

      const { error } = await supabase.auth.signOut();
      
      if (error) {
        if (error.message.includes('session_not_found')) {
          console.log("[UserActions] Session not found, redirecting to login");
          navigate('/login', { replace: true });
          return;
        }
        throw error;
      }
      
      console.log("[UserActions] Sign out successful");
      toast({
        title: "Signed out successfully",
        description: "You have been signed out of your account.",
      });
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('[UserActions] Error signing out:', error);
      // If any error occurs, still redirect to login for safety
      navigate('/login', { replace: true });
      toast({
        title: "Notice",
        description: "You have been signed out.",
      });
    }
  };

  return (
    <div className="flex justify-end items-center gap-2 md:gap-4">
      <NotificationsButton notifications={notifications} />
      {showProfileButton && (
        <Button
          variant="ghost"
          size="icon"
          onClick={() => navigate('/app/profile')}
          className="text-foreground hover:text-foreground/80"
        >
          <User className="h-5 w-5" />
        </Button>
      )}
      <Button
        variant="ghost"
        size="icon"
        onClick={handleSignOut}
        className="text-foreground hover:text-foreground/80"
      >
        <LogOut className="h-5 w-5" />
      </Button>
    </div>
  );
};
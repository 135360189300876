

import { DashboardHeader } from "@/components/DashboardHeader";
import { H1, Body, H3, H4, BodySmall } from "@/components/Typography";
import { Footer } from "@/components/Footer";
import { Separator } from "@/components/ui/separator";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-background flex flex-col">
      <DashboardHeader notifications={[]} queuePosition={0} showProfileButton={false} />
      
      <main className="container mx-auto px-4 py-8 flex-grow">
        <div className="max-w-4xl mx-auto">
          <H1 className="mb-4 text-center md:text-left">Privacy Policy</H1>
          <BodySmall className="text-muted-foreground mb-8">(for Jungl ApS websites and apps)</BodySmall>
          
          <div className="prose prose-invert max-w-none space-y-4">
            <section>
              <H4 className="mb-2 text-xl">1. Purpose of This Policy</H4>
              <BodySmall className="leading-relaxed">
                The purpose of this privacy policy is to inform you of how Jungl ApS ("Jungl", "we", "us")
                processes your personal data. We wish to make you aware of the information we collect, the
                reasons for processing it, and, where applicable, the duration of storage. This policy governs
                the processing of personal data in connection with your interactions, registration, and use of
                our websites, apps, and digital services.
              </BodySmall>
            </section>
            
            <Separator className="my-4" />
            
            <section>
              <H4 className="mb-2 text-xl">2. Description of Processing</H4>
              <BodySmall className="leading-relaxed">
                Jungl processes your personal data for one or more specific purposes and in accordance with
                the applicable data protection regulations, including the GDPR. We process your information
                if you are a customer, have created a brand or influencer account on our platform, applied for
                a position with us, or signed up for our newsletter. Generally, the information is provided
                directly by you, and we retain and process it only for as long as necessary to fulfill the
                purposes for which it was collected.
              </BodySmall>
              <BodySmall className="mt-3 leading-relaxed">
                Below are the primary categories and purposes for which we process your data:
              </BodySmall>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">2.1 Managing Purchases and Subscriptions</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  When you purchase our services or subscribe to our platform, we may process the personal
                  data of your company's contact person. This includes:
                </BodySmall>
                <ul className="list-disc pl-6 my-2">
                  <li><BodySmall>Contact details: Name, company name, position, telephone number, and email address</BodySmall></li>
                  <li><BodySmall>Transaction details: Purchase history and payment information</BodySmall></li>
                </ul>
                <BodySmall className="mt-2 leading-relaxed">
                  <span className="font-semibold">Legal basis:</span> Processing is necessary for the performance of a contract with you (GDPR
                  Article 6(1)(b)).
                </BodySmall>
                <BodySmall className="mt-2 leading-relaxed">
                  <span className="font-semibold">Retention:</span> We retain data related to purchases and subscriptions for the current financial
                  year plus 5 years (in accordance with the Danish Bookkeeping Act).
                </BodySmall>
              </div>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">2.2 Paying Customers (Brands)</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  For our business customers who subscribe to our digital platform or collaborate with us, we
                  process general personal information of employees or representatives. This information is
                  used to:
                </BodySmall>
                <ul className="list-disc pl-6 my-2">
                  <li><BodySmall>Enhance and improve our platform and services</BodySmall></li>
                  <li><BodySmall>Contact you regarding guidance on using our digital platform or subscription options</BodySmall></li>
                </ul>
                <BodySmall className="leading-relaxed">Data collected may include:</BodySmall>
                <ul className="list-disc pl-6 my-2">
                  <li><BodySmall>Email address</BodySmall></li>
                  <li><BodySmall>Phone number</BodySmall></li>
                  <li><BodySmall>Job title</BodySmall></li>
                  <li><BodySmall>Company name</BodySmall></li>
                  <li><BodySmall>Full name of the contact person</BodySmall></li>
                  <li><BodySmall>Business registration details (if applicable)</BodySmall></li>
                </ul>
                <BodySmall className="mt-2 leading-relaxed">
                  <span className="font-semibold">Legal basis:</span> We process your data to enter into and fulfill a service agreement with you
                  (GDPR Article 6(1)(b)).
                </BodySmall>
              </div>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">2.3 Influencer Profile</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  When you create an influencer account on our platform, we process your personal
                  information to help match you with brand campaigns and collaborations. Typically, we
                  collect standard personal data such as:
                </BodySmall>
                <ul className="list-disc pl-6 my-2">
                  <li><BodySmall>Basic information: Name, profile picture, biography, email address, phone number, and address</BodySmall></li>
                  <li><BodySmall>Additional details: Country, gender, birthday, and categories of interest</BodySmall></li>
                  <li><BodySmall>Identification and verification: In some cases, additional data (e.g., personal identification number, passport or driver's license details, bank information, tax identification number) may be required</BodySmall></li>
                </ul>
                <BodySmall className="mt-2 leading-relaxed">
                  If you choose to connect your social media accounts (e.g., Facebook, Instagram, TikTok)
                  with your Jungl profile, we will also collect information available through those platforms
                  according to your privacy settings and the respective platform's privacy policies. This may
                  include:
                </BodySmall>
                <ul className="list-disc pl-6 my-2">
                  <li><BodySmall>Social media username and profile name</BodySmall></li>
                  <li><BodySmall>Profile picture and biography</BodySmall></li>
                  <li><BodySmall>Public engagement data (e.g., likes, comments, insights)</BodySmall></li>
                </ul>
                <BodySmall className="mt-2 leading-relaxed">
                  <span className="font-semibold">Sensitive Data:</span> We process any sensitive personal data (e.g., regarding race, ethnic origin,
                  etc.) only if it is made publicly available or provided by you.
                </BodySmall>
                <BodySmall className="mt-2 leading-relaxed">
                  <span className="font-semibold">Consent and Retention:</span>
                </BodySmall>
                <ul className="list-disc pl-6 my-2">
                  <li><BodySmall>Registration as an influencer is based on your explicit consent (GDPR Article 6(1)(a)). You may withdraw this consent at any time by contacting us at support@jungl.co.</BodySmall></li>
                  <li><BodySmall>Your influencer profile will remain active until you request deletion or up to 3 years after your last login, after which it will be removed.</BodySmall></li>
                  <li><BodySmall>Payment-related data connected with influencer collaborations will be retained for the current financial year plus 5 years in accordance with the Danish Bookkeeping Act.</BodySmall></li>
                </ul>
              </div>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">2.4 Optimization of Services</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  To continuously improve our platform and services, we collect and analyze various data
                  points to understand user interactions. These data points are gathered when you register for
                  an account and during your use of our services. The information may include:
                </BodySmall>
                <ul className="list-disc pl-6 my-2">
                  <li><BodySmall>Name, gender, and birthdate</BodySmall></li>
                  <li><BodySmall>Company and address details</BodySmall></li>
                  <li><BodySmall>Email address and phone number</BodySmall></li>
                  <li><BodySmall>Payment information and data provided via forms</BodySmall></li>
                </ul>
                <BodySmall className="mt-2 leading-relaxed">
                  <span className="font-semibold">Anonymization & Consent:</span>
                </BodySmall>
                <ul className="list-disc pl-6 my-2">
                  <li><BodySmall>Most of these data points are anonymized before analysis.</BodySmall></li>
                  <li><BodySmall>If non-anonymized data is required for a particular purpose, we will seek your explicit consent (GDPR Article 6(1)(a)). You may withdraw your consent at any time.</BodySmall></li>
                  <li><BodySmall>In cases where no explicit consent is obtained, processing may be carried out based on our legitimate interest (GDPR Article 6(1)(f)) provided this does not conflict with your rights.</BodySmall></li>
                </ul>
                <BodySmall className="mt-2 leading-relaxed">
                  <span className="font-semibold">Retention:</span> Non-anonymized data is generally kept for up to 3 years before being deleted or
                  anonymized.
                </BodySmall>
              </div>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">2.5 Website Data Collection</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  When you visit our website, we use cookies and similar technologies (such as pixels) to
                  collect personal data. This helps us:
                </BodySmall>
                <ul className="list-disc pl-6 my-2">
                  <li><BodySmall>Enhance the website's appearance and user experience</BodySmall></li>
                  <li><BodySmall>Gather statistics on your visit (e.g., activity, preferences, behavior, IP address, browser type)</BodySmall></li>
                </ul>
                <BodySmall className="mt-2 leading-relaxed">
                  <span className="font-semibold">Consent:</span>
                </BodySmall>
                <ul className="list-disc pl-6 my-2">
                  <li><BodySmall>Personal data is only collected with your prior consent, which you may withdraw at any time.</BodySmall></li>
                  <li><BodySmall>For further details, please refer to our cookie policy available on our website.</BodySmall></li>
                </ul>
              </div>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">2.6 Customer Relationship Management and Support</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  To provide you with support and manage our relationship, we process your personal data,
                  including:
                </BodySmall>
                <ul className="list-disc pl-6 my-2">
                  <li><BodySmall>Name, company, position, telephone number, email address</BodySmall></li>
                  <li><BodySmall>Details of your support inquiries</BodySmall></li>
                </ul>
                <BodySmall className="mt-2 leading-relaxed">
                  <span className="font-semibold">Legal basis:</span> This processing is necessary to fulfill our contractual obligations (GDPR Article
                  6(1)(b)).
                </BodySmall>
                <BodySmall className="mt-2 leading-relaxed">
                  <span className="font-semibold">Retention:</span> We delete such data on an ongoing basis, but no later than 3 years after the end of
                  our customer relationship.
                </BodySmall>
              </div>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">2.7 Job Applicants</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  If you apply for a job at Jungl, we process your personal data to evaluate your suitability for
                  the role. This may include:
                </BodySmall>
                <ul className="list-disc pl-6 my-2">
                  <li><BodySmall>Name, address, telephone number, email address, and birthdate</BodySmall></li>
                  <li><BodySmall>Application documents such as your CV, cover letter, photographs, academic records</BodySmall></li>
                  <li><BodySmall>Test results, references, and interview notes</BodySmall></li>
                </ul>
                <BodySmall className="mt-2 leading-relaxed">
                  <span className="font-semibold">Legal basis:</span> Processing is necessary to assess your application and, where applicable, to
                  enter into an employment agreement (GDPR Article 6(1)(b)). We may also process certain
                  information based on our legitimate interest (GDPR Article 6(1)(f)).
                </BodySmall>
                <BodySmall className="mt-2 leading-relaxed">
                  <span className="font-semibold">Retention:</span> Your data is stored until the position is filled. If we wish to keep your data for
                  future opportunities beyond the recruitment process, we will obtain your explicit consent.
                </BodySmall>
              </div>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">2.8 Newsletters and Marketing Communications</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  If you subscribe to our newsletter or marketing communications, we process your name and
                  email address to send you updates, news, and promotional materials.
                </BodySmall>
                <BodySmall className="mt-2 leading-relaxed">
                  <span className="font-semibold">Legal basis:</span> Processing is based on your consent (GDPR Article 6(1)(a)).
                </BodySmall>
                <BodySmall className="mt-2 leading-relaxed">
                  <span className="font-semibold">Withdrawal:</span> You may withdraw your consent at any time by contacting us at
                  support@jungl.co or by clicking the unsubscribe link provided in every email.
                </BodySmall>
                <BodySmall className="mt-2 leading-relaxed">
                  <span className="font-semibold">Retention:</span> We retain a record of your consent for 2 years after you unsubscribe (this period
                  corresponds with the expiry of any potential criminal liability).
                </BodySmall>
              </div>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">2.9 Suppliers and Collaborators</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  When entering into agreements with suppliers or collaborators, we process the personal data
                  of their contact persons, which may include:
                </BodySmall>
                <ul className="list-disc pl-6 my-2">
                  <li><BodySmall>Name, position, telephone number, email address, and, if necessary, payment information</BodySmall></li>
                </ul>
                <BodySmall className="mt-2 leading-relaxed">
                  <span className="font-semibold">Legal basis:</span> Processing is necessary to establish an agreement (GDPR Article 6(1)(b)) or is
                  based on our legitimate interests (GDPR Article 6(1)(f)).
                </BodySmall>
                <BodySmall className="mt-2 leading-relaxed">
                  <span className="font-semibold">Retention:</span> Written correspondence is deleted continuously, while data required for
                  compliance with bookkeeping regulations is stored for the current financial year plus 5 years.
                </BodySmall>
              </div>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">2.10 Notification by Statutory Processing</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  In certain cases, we process your personal data based on legal requirements or to establish a
                  customer relationship. You are required to provide the necessary information so that we can:
                </BodySmall>
                <ul className="list-disc pl-6 my-2">
                  <li><BodySmall>Establish and maintain the relationship</BodySmall></li>
                  <li><BodySmall>Provide you with an account, services, or to issue invoices</BodySmall></li>
                </ul>
                <BodySmall className="mt-2 leading-relaxed">
                  If you choose not to provide the required data, we may be unable to establish or continue our
                  relationship with you.
                </BodySmall>
              </div>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">2.11 Recipients of Personal Data</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  We treat your personal data confidentially and do not share it with third parties except under
                  the following circumstances:
                </BodySmall>
                <ul className="list-disc pl-6 my-2">
                  <li><BodySmall>When necessary to perform our services (e.g., system and hosting providers, payment service providers, support providers, social media marketing partners)</BodySmall></li>
                  <li><BodySmall>When you have given your explicit consent</BodySmall></li>
                  <li><BodySmall>When disclosure is required by law or based on our legitimate interests</BodySmall></li>
                </ul>
                <BodySmall className="mt-2 leading-relaxed">
                  Some third-party service providers with whom we share your data may be located outside the
                  EU/EEA (for example, in the USA). In such cases, we ensure that appropriate legal
                  safeguards are in place (including the use of EU Commission Standard Contractual Clauses).
                  If you wish to review the legal basis for any cross-border data transfers, please contact us at
                  support@jungl.co.
                </BodySmall>
              </div>
            </section>
            
            <Separator className="my-4" />
            
            <section>
              <H4 className="mb-2 text-xl">3. Your Rights</H4>
              <BodySmall className="leading-relaxed">
                Under applicable data protection laws, you have the following rights regarding your personal
                data:
              </BodySmall>
              <ul className="list-disc pl-6 my-3">
                <li><BodySmall className="leading-relaxed"><span className="font-semibold">Access:</span> The right to request access to your personal data.</BodySmall></li>
                <li><BodySmall className="leading-relaxed"><span className="font-semibold">Rectification:</span> The right to request correction of any inaccurate or incomplete data.</BodySmall></li>
                <li><BodySmall className="leading-relaxed"><span className="font-semibold">Erasure:</span> The right to request deletion of your personal data (subject to applicable legal restrictions).</BodySmall></li>
                <li><BodySmall className="leading-relaxed"><span className="font-semibold">Restriction:</span> The right to request limitations on how your data is processed.</BodySmall></li>
                <li><BodySmall className="leading-relaxed"><span className="font-semibold">Objection:</span> The right to object to processing based on our legitimate interests.</BodySmall></li>
                <li><BodySmall className="leading-relaxed"><span className="font-semibold">Data Portability:</span> The right to receive your data in a structured, commonly used, and machine-readable format.</BodySmall></li>
              </ul>
              <BodySmall className="leading-relaxed">
                If you have given your consent to our processing, you also have the right to withdraw that
                consent at any time. To exercise any of these rights—or if you wish to have your associated
                profile deleted—please contact us at support@jungl.co.
              </BodySmall>
              <BodySmall className="mt-3 leading-relaxed">
                If you are dissatisfied with our handling of your personal data, you have the right to lodge a
                complaint with the Danish Data Protection Agency (Datatilsynet) via their website at
                www.datatilsynet.dk/english or by phone at +45 33 19 32 00.
              </BodySmall>
            </section>
            
            <Separator className="my-4" />
            
            <section>
              <H4 className="mb-2 text-xl">4. Our Contact Information</H4>
              <BodySmall className="leading-relaxed">
                The company responsible for processing your personal data is:
              </BodySmall>
              <div className="my-3 pl-2">
                <BodySmall className="leading-relaxed">Jungl ApS</BodySmall>
                <BodySmall className="leading-relaxed">CVR: 45319717</BodySmall>
                <BodySmall className="leading-relaxed">Address: Kronprinsessegade 54, 5. tv, 1306 København K, Danmark</BodySmall>
                <BodySmall className="leading-relaxed">Email: support@jungl.co</BodySmall>
              </div>
              <BodySmall className="leading-relaxed">
                If you have any questions regarding this privacy policy or our data processing practices,
                please do not hesitate to contact us.
              </BodySmall>
            </section>
            
            <Separator className="my-4" />
            
            <section>
              <H4 className="mb-2 text-xl">5. Keeping Your Data Safe</H4>
              <BodySmall className="leading-relaxed">
                We employ reasonable physical, technical, and organizational measures to protect your
                personal data within our organization. However, please be aware that the Internet is not a
                completely secure environment, and we cannot guarantee the absolute security of data
                transmitted to us. For this reason, we advise you not to include any confidential or highly
                sensitive information when communicating with us via email.
              </BodySmall>
            </section>
            
            <Separator className="my-4" />
            
            <section>
              <H4 className="mb-2 text-xl">6. Revision of This Privacy Policy</H4>
              <BodySmall className="leading-relaxed">
                We reserve the right to amend or update this privacy policy from time to time. In the event of
                significant changes, we will notify you via email or by posting a prominent notice on our
                website.
              </BodySmall>
            </section>
            
            <div className="mt-8 pt-4 border-t border-border">
              <BodySmall className="text-muted-foreground">Last Revised: February 10, 2025</BodySmall>
            </div>
          </div>
        </div>
      </main>
      
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;

import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ApplicationFormData, ApplicationFormProps } from "@/types/applications";
import { useToast } from "@/hooks/use-toast";
import { Loader2 } from "lucide-react";

export const ApplicationForm = ({ onSubmit, isLoading = false }: ApplicationFormProps) => {
  const { register, handleSubmit, formState: { errors } } = useForm<ApplicationFormData>();
  const [socialLinks, setSocialLinks] = useState([{ platform: "", url: "" }]);
  const { toast } = useToast();

  const handleFormSubmit = async (data: ApplicationFormData) => {
    try {
      data.socialLinks = socialLinks.filter(link => link.platform && link.url);
      await onSubmit(data);
      toast({
        title: "Application Submitted",
        description: "Your application has been submitted successfully.",
      });
    } catch (error) {
      console.error("[ApplicationForm] Error submitting application:", error);
      toast({
        title: "Error",
        description: "Failed to submit application. Please try again.",
        variant: "destructive",
      });
    }
  };

  const addSocialLink = () => {
    setSocialLinks([...socialLinks, { platform: "", url: "" }]);
  };

  return (
    <Card className="w-full max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">Creator Application</CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-6">
          <div className="space-y-2">
            <label className="text-sm font-medium">Full Name</label>
            <Input
              {...register("fullName", { required: "Full name is required" })}
              className="w-full"
              placeholder="Enter your full name"
            />
            {errors.fullName && (
              <p className="text-sm text-red-500">{errors.fullName.message}</p>
            )}
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">Email</label>
            <Input
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
              type="email"
              className="w-full"
              placeholder="Enter your email"
            />
            {errors.email && (
              <p className="text-sm text-red-500">{errors.email.message}</p>
            )}
          </div>

          <div className="space-y-4">
            <label className="text-sm font-medium">Social Links</label>
            {socialLinks.map((link, index) => (
              <div key={index} className="flex gap-4">
                <Input
                  value={link.platform}
                  onChange={(e) => {
                    const newLinks = [...socialLinks];
                    newLinks[index].platform = e.target.value;
                    setSocialLinks(newLinks);
                  }}
                  placeholder="Platform (e.g., Twitter, Instagram)"
                  className="flex-1"
                />
                <Input
                  value={link.url}
                  onChange={(e) => {
                    const newLinks = [...socialLinks];
                    newLinks[index].url = e.target.value;
                    setSocialLinks(newLinks);
                  }}
                  placeholder="Profile URL"
                  className="flex-1"
                />
              </div>
            ))}
            <Button
              type="button"
              variant="outline"
              onClick={addSocialLink}
              className="w-full"
            >
              Add Social Link
            </Button>
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">Bio</label>
            <Textarea
              {...register("bio", { required: "Bio is required" })}
              className="w-full min-h-[100px]"
              placeholder="Tell us about yourself..."
            />
            {errors.bio && (
              <p className="text-sm text-red-500">{errors.bio.message}</p>
            )}
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">Why do you want to join?</label>
            <Textarea
              {...register("motivation", { required: "This field is required" })}
              className="w-full min-h-[100px]"
              placeholder="Share your motivation for joining..."
            />
            {errors.motivation && (
              <p className="text-sm text-red-500">{errors.motivation.message}</p>
            )}
          </div>

          <Button type="submit" className="w-full" disabled={isLoading}>
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Submitting...
              </>
            ) : (
              "Submit Application"
            )}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};
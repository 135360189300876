
import { supabase } from "@/integrations/supabase/client";
import { useSupabaseQuery } from "./useSupabaseQuery";

interface QueuePosition {
  queue_position: number;
  total_count: number;
}

export const useQueuePosition = (userId: string) => {
  console.log("[useQueuePosition] Initializing hook for user:", userId);

  const { data, isLoading, error } = useSupabaseQuery<QueuePosition>({
    queryKey: `queue-position-${userId}`,
    queryFn: async () => {
      console.log("[useQueuePosition] Fetching queue position");
      const { data, error } = await supabase
        .rpc('get_queue_position', { user_id: userId });

      if (error) {
        console.error("[useQueuePosition] Error fetching queue position:", error);
        throw error;
      }

      if (!data?.[0]) {
        console.log("[useQueuePosition] No queue position found for user");
        return { queue_position: 0, total_count: 0 };
      }

      console.log("[useQueuePosition] Successfully fetched queue data:", data[0]);
      return {
        queue_position: Number(data[0].queue_position),
        total_count: Number(data[0].total_count)
      };
    },
    enabled: !!userId
  });

  return { 
    queuePosition: data?.queue_position || 0,
    totalCount: data?.total_count || 0,
    isLoading, 
    error 
  };
};

import { NotificationsButton } from "../NotificationsButton";
import { RewardsButton } from "../RewardsButton";
import { Button } from "../ui/button";
import { LogOut, LogIn } from "lucide-react";
import { InviteDialog } from "../InviteDialog";

interface NavigationItemsProps {
  notifications: any[];
  rewards: {
    points: number;
    badges: string[];
  };
  isAuthenticated: boolean;
  onSignOut: () => Promise<void>;
  onSignIn: () => void;
}

export const NavigationItems = ({ 
  notifications, 
  rewards, 
  isAuthenticated, 
  onSignOut, 
  onSignIn 
}: NavigationItemsProps) => {
  return (
    <>
      {isAuthenticated ? (
        <>
          <NotificationsButton notifications={notifications} />
          <RewardsButton rewards={rewards} />
          <Button
            variant="ghost"
            size="icon"
            onClick={onSignOut}
            className="text-foreground hover:text-foreground/80"
          >
            <LogOut className="h-5 w-5" />
          </Button>
        </>
      ) : (
        <>
          <InviteDialog />
          <Button
            variant="ghost"
            size="icon"
            onClick={onSignIn}
            className="text-foreground hover:text-foreground/80"
          >
            <LogIn className="h-5 w-5" />
          </Button>
        </>
      )}
    </>
  );
};
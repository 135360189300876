
import { DashboardHeader } from "@/components/DashboardHeader";
import { H1, H3, BodyLarge } from "@/components/Typography";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { Mail } from "lucide-react";
import { Footer } from "@/components/Footer";

const SupportPage = () => {
  return (
    <div className="min-h-screen bg-background text-foreground flex flex-col">
      <DashboardHeader notifications={[]} queuePosition={0} showProfileButton={false} />
      
      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="max-w-2xl mx-auto">
          <H1 className="mb-8 text-center">Support Center</H1>
          
          <BodyLarge className="text-center mb-12 text-muted-foreground">
            We're here to help. Get in touch with our support team.
          </BodyLarge>
          
          <Card className="bg-card border-border mb-8">
            <CardHeader>
              <div className="flex items-center gap-3">
                <div className="p-2 rounded-lg bg-primary/10">
                  <Mail className="h-6 w-6 text-primary" />
                </div>
                <H3>Email Support</H3>
              </div>
            </CardHeader>
            <CardContent>
              <p className="text-muted-foreground mb-4">
                Send us an email and we'll get back to you within 24 hours.
              </p>
              <Button 
                variant="default" 
                className="w-full"
                onClick={() => window.location.href = 'mailto:support@jungl.co'}
              >
                Send Email
              </Button>
            </CardContent>
          </Card>

          <div className="text-center text-muted-foreground">
            <H3 className="mb-4">Company Information</H3>
            <p className="mb-2">Jungl ApS</p>
            <p className="mb-2">Kronprinsessegade 54, 5. tv</p>
            <p className="mb-2">1306 København K, Danmark</p>
            <p className="mb-2">CVR: 45319717</p>
            <p>Email: support@jungl.co</p>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default SupportPage;

import { supabase } from "@/integrations/supabase/client";
import { useSupabaseQuery } from "./useSupabaseQuery";
import type { NotificationsTable } from "@/integrations/supabase/types/notifications";

export const useNotifications = (userId: string) => {
  console.log("[useNotifications] Initializing hook for user:", userId);

  const { data, isLoading, error } = useSupabaseQuery<NotificationsTable['Row'][]>({
    queryKey: `notifications-${userId}`,
    queryFn: async () => {
      console.log("[useNotifications] Fetching notifications for user:", userId);
      const { data, error } = await supabase
        .from('notifications')
        .select('*')
        .eq('user_id', userId)
        .order('created_at', { ascending: false });

      if (error) {
        console.error("[useNotifications] Error fetching notifications:", error);
        throw error;
      }

      console.log("[useNotifications] Successfully fetched notifications:", {
        count: data?.length,
        unreadCount: data?.filter(n => !n.read).length
      });

      return data;
    },
    enabled: !!userId
  });

  return { 
    notifications: data || [], 
    isLoading, 
    error 
  };
};
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/contexts/AuthContext";
import { useToast } from "@/hooks/use-toast";
import { Navigation } from "@/components/Navigation";

export default function LoginPage() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { toast } = useToast();

  useEffect(() => {
    const params = new URLSearchParams(window.location.hash.substring(1));
    const hasRecoveryToken = params.get('type') === 'recovery';
    
    console.log("[LoginPage] Checking auth state:", { 
      hasUser: !!user, 
      hasRecoveryToken,
      hash: window.location.hash,
      params: Object.fromEntries(params.entries())
    });

    // Only redirect if user is logged in and there's no recovery token
    if (user && !hasRecoveryToken) {
      console.log("[LoginPage] User already logged in, redirecting to dashboard", {
        userId: user.id
      });
      navigate('/waitlist-dashboard', { replace: true });
    }
  }, [user, navigate]);

  useEffect(() => {
    console.log("[LoginPage] Setting up auth state listener");
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log("[LoginPage] Auth state changed:", { 
        event, 
        hasSession: !!session,
        userId: session?.user?.id,
        email: session?.user?.email,
        accessToken: !!session?.access_token,
        recoveryToken: window.location.hash.includes('type=recovery')
      });
      
      if (event === 'SIGNED_IN') {
        console.log("[LoginPage] User signed in successfully");
        toast({
          title: "Welcome back!",
          description: "You've been successfully signed in.",
        });
        navigate('/waitlist-dashboard', { replace: true });
      }
    });

    return () => {
      console.log("[LoginPage] Cleaning up auth listener");
      subscription.unsubscribe();
    };
  }, [toast, navigate]);

  return (
    <div className="min-h-screen bg-black">
      <Navigation notifications={[]} rewards={{ points: 0, badges: [] }} />
      <div className="min-h-[calc(100vh-80px)] flex items-center justify-center p-4">
        <div className="w-full max-w-md bg-zinc-900 p-8 rounded-lg shadow-lg">
          <h1 className="text-2xl font-bold text-white mb-6 text-center">Welcome to Jungle</h1>
          <Auth
            supabaseClient={supabase}
            appearance={{
              theme: ThemeSupa,
              variables: {
                default: {
                  colors: {
                    brand: '#18181b',
                    brandAccent: '#27272a',
                    inputBackground: '#27272a',
                    inputText: 'white',
                    inputPlaceholder: 'darkgray',
                  }
                }
              },
              className: {
                container: 'auth-container',
                label: 'text-white',
                button: 'bg-zinc-800 hover:bg-zinc-700 text-white',
                input: 'bg-zinc-800 border-zinc-700 text-white',
                anchor: 'text-zinc-400 hover:text-white transition-colors'
              }
            }}
            providers={[]}
            redirectTo={`${window.location.origin}/waitlist-dashboard`}
            showLinks={true}
            view="sign_up"
            localization={{
              variables: {
                sign_in: {
                  email_label: 'Email',
                  password_label: 'Password',
                  email_input_placeholder: 'Your email',
                  password_input_placeholder: 'Your password',
                  button_label: 'Sign in',
                  loading_button_label: 'Signing in ...',
                  link_text: 'Already have an account? Sign in',
                },
                sign_up: {
                  email_label: 'Email',
                  password_label: 'Password',
                  email_input_placeholder: 'Your email',
                  password_input_placeholder: 'Your password',
                  button_label: 'Sign up',
                  loading_button_label: 'Signing up ...',
                  link_text: 'Don\'t have an account? Sign up',
                },
                forgotten_password: {
                  email_label: 'Email',
                  password_label: 'Password',
                  email_input_placeholder: 'Your email',
                  button_label: 'Send reset instructions',
                  loading_button_label: 'Sending reset instructions...',
                  link_text: 'Forgot your password?',
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

import { useState, useEffect } from "react";
import { NavigationContainer } from "@/components/navigation/NavigationContainer";
import { CreatorGrid } from "@/components/CreatorGrid";
import { InviteDialog } from "@/components/InviteDialog";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { H1, BodyLarge } from "@/components/Typography";
import { Footer } from "@/components/Footer";

const Index = () => {
  const [notifications, setNotifications] = useState([]);
  const [rewards, setRewards] = useState({ points: 0, badges: [] });
  const [userCount, setUserCount] = useState(733); // Initialize with base count
  const { toast } = useToast();

  useEffect(() => {
    console.log("[Index] Setting up notification subscription");
    
    const channel = supabase
      .channel('schema-db-changes')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'notifications'
        },
        (payload) => {
          console.log("[Index] New notification received:", payload);
          toast({
            title: payload.new.title,
            description: payload.new.message,
          });
          setNotifications(prev => [payload.new, ...prev]);
        }
      )
      .subscribe();

    return () => {
      console.log("[Index] Cleaning up notification subscription");
      supabase.removeChannel(channel);
    };
  }, [toast]);

  useEffect(() => {
    const fetchUserCount = async () => {
      console.log("[Index] Starting to fetch user count");
      try {
        const { count, error } = await supabase
          .from('profiles')
          .select('*', { count: 'exact', head: true });
        
        if (error) {
          console.error("[Index] Error fetching user count:", error);
          return;
        }

        console.log("[Index] Successfully fetched user count:", count);
        // Add the base count (733) to the actual count
        setUserCount(733 + (count || 0));
      } catch (error) {
        console.error("[Index] Exception in fetchUserCount:", error);
      }
    };

    fetchUserCount();

    const channel = supabase
      .channel('profiles-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'profiles'
        },
        () => {
          console.log("[Index] Profiles table changed, updating count");
          fetchUserCount();
        }
      )
      .subscribe();

    return () => {
      console.log("[Index] Cleaning up profiles subscription");
      supabase.removeChannel(channel);
    };
  }, []);

  return (
    <div className="min-h-screen bg-black text-white flex flex-col">
      <NavigationContainer notifications={notifications} rewards={rewards} />

      <main className="flex-grow">
        <div className="max-w-3xl mx-auto text-center px-4 mt-8 md:mt-16 lg:mt-32 mb-8 md:mb-12 lg:mb-20">
          <H1 className="text-3xl md:text-4xl lg:text-5xl mb-4 md:mb-6 lg:mb-8">
            Join the most exclusive creator management platform.
          </H1>
          
          <BodyLarge className="text-base md:text-lg lg:text-xl mb-3 md:mb-4 lg:mb-6 text-gray-300">
            Jungle is invite-only. Join the waitlist to get early access and move up the list by inviting other creators.
          </BodyLarge>
          
          <BodyLarge className="text-base md:text-lg lg:text-xl mb-6 md:mb-8 lg:mb-12 text-gray-300">
            <span className="text-green-400">{userCount.toLocaleString()} creators</span> are already waiting to join.
          </BodyLarge>
          
          <InviteDialog />
        </div>

        <CreatorGrid />
      </main>

      <Footer />
    </div>
  );
};

export default Index;


import { Link } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";

export const Logo = ({ className = "" }: { className?: string }) => {
  const { user } = useAuth();
  
  // Determine the target route based on authentication status
  const targetRoute = user ? "/app/dashboard" : "/";

  return (
    <Link to={targetRoute} className={`block ${className}`}>
      <img
        src="https://qwrtzbicgmhtilxtskmo.supabase.co/storage/v1/object/public/logos/logo%20white.png"
        alt="Jungle Logo"
        className="h-8 md:h-10 w-auto object-contain"
        style={{ maxWidth: '120px' }}
      />
    </Link>
  );
};

import { Button } from "@/components/ui/button";
import { Award } from "lucide-react";

interface RewardsButtonProps {
  rewards: {
    points: number;
    badges: string[];
  };
}

export const RewardsButton = ({ rewards }: RewardsButtonProps) => {
  return (
    <Button
      variant="ghost"
      size="icon"
      onClick={() => console.log("Show rewards")}
    >
      <Award className="h-5 w-5" />
    </Button>
  );
};
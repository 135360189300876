import { createContext, useContext, useEffect, useState } from "react";
import { User, Session } from "@supabase/supabase-js";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";

interface AuthContextType {
  user: User | null;
  session: Session | null;
  isLoading: boolean;
}

const AuthContext = createContext<AuthContextType>({ 
  user: null, 
  session: null, 
  isLoading: true 
});

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [session, setSession] = useState<Session | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();

  useEffect(() => {
    // Get initial session
    const initializeAuth = async () => {
      try {
        const { data: { session: initialSession }, error } = await supabase.auth.getSession();
        
        if (error) {
          console.error("[AuthProvider] Error getting initial session:", error);
          // Clear potentially corrupted session data
          await supabase.auth.signOut();
          return;
        }
        
        if (initialSession) {
          console.log("[AuthProvider] Initial session found");
          setSession(initialSession);
          setUser(initialSession.user);
        }
      } catch (error) {
        console.error("[AuthProvider] Unexpected error during initialization:", error);
      } finally {
        setIsLoading(false);
      }
    };

    initializeAuth();

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, currentSession) => {
      console.log("[AuthProvider] Auth state changed:", { event });
      
      if (event === 'TOKEN_REFRESHED') {
        console.log("[AuthProvider] Token refreshed successfully");
      }

      if (event === 'SIGNED_OUT') {
        console.log("[AuthProvider] User signed out");
        setSession(null);
        setUser(null);
      }

      if (currentSession) {
        setSession(currentSession);
        setUser(currentSession.user);
      }

      setIsLoading(false);
    });

    // Cleanup subscription
    return () => {
      subscription.unsubscribe();
    };
  }, [toast]);

  return (
    <AuthContext.Provider value={{ user, session, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
}
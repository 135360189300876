import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { LogOut } from "lucide-react";

export function AdminImpersonationBanner() {
  const [adminId, setAdminId] = useState<string | null>(null);
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    const storedAdminId = localStorage.getItem('admin_session');
    if (storedAdminId) {
      setAdminId(storedAdminId);
    }
  }, []);

  const handleEndImpersonation = async () => {
    try {
      if (!adminId) return;

      // Sign back in as admin
      const { error: signInError } = await supabase.auth.signInWithPassword({
        email: 'admin@example.com', // This needs to be handled properly in production
        password: 'admin-password'
      });

      if (signInError) throw signInError;

      // Clear the stored admin session
      localStorage.removeItem('admin_session');
      setAdminId(null);

      toast({
        title: "Success",
        description: "Returned to admin account",
      });

      // Navigate back to admin dashboard
      navigate('/app/dashboard');
    } catch (error) {
      console.error('[AdminImpersonationBanner] Error ending impersonation:', error);
      toast({
        title: "Error",
        description: "Failed to end impersonation session",
        variant: "destructive",
      });
    }
  };

  if (!adminId) return null;

  return (
    <Alert variant="destructive" className="fixed bottom-4 right-4 w-auto max-w-md flex items-center justify-between">
      <AlertDescription>
        Viewing as user (Admin Mode)
      </AlertDescription>
      <Button
        variant="outline"
        size="sm"
        onClick={handleEndImpersonation}
        className="ml-4 bg-white text-red-600 hover:bg-red-50"
      >
        <LogOut className="h-4 w-4 mr-2" />
        End Session
      </Button>
    </Alert>
  );
}
import { QueueCounter } from "./dashboard/QueueCounter";
import { UserActions } from "./dashboard/UserActions";

interface DashboardHeaderProps {
  notifications: any[];
  queuePosition: number;
  showProfileButton?: boolean;
}

export const DashboardHeader = ({ notifications, queuePosition, showProfileButton = true }: DashboardHeaderProps) => {
  return (
    <nav className="flex justify-between items-center p-4 md:p-6 bg-background border-b border-border">
      <div className="flex-1 flex justify-start">
        <QueueCounter queuePosition={queuePosition} />
      </div>
      
      <div className="flex-1 flex justify-center items-center">
        <img 
          src="https://qwrtzbicgmhtilxtskmo.supabase.co/storage/v1/object/public/logos/logo%20white.png" 
          alt="JUNGLE"
          className="h-8 md:h-10 w-auto object-contain"
          style={{ maxWidth: '120px' }}
        />
      </div>

      <div className="flex-1">
        <UserActions 
          notifications={notifications} 
          showProfileButton={showProfileButton} 
        />
      </div>
    </nav>
  );
};
import { H1, BodyLarge } from "@/components/Typography";

export const InviteHeader = () => {
  return (
    <div className="text-center space-y-6 mb-12">
      <H1 className="text-3xl md:text-4xl lg:text-5xl text-white">
        Welcome to Jungle
      </H1>
      <BodyLarge className="text-white max-w-xl mx-auto">
        Join the exclusive community of creators shaping the future
      </BodyLarge>
    </div>
  );
};
import { useEffect } from "react";
import { Navigation } from "@/components/Navigation";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-background">
      <Navigation notifications={[]} rewards={{ points: 0, badges: [] }} />
      
      <main className="container mx-auto px-4 py-8 md:py-12 max-w-[1800px]">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
          {/* Left side - MANIFESTO */}
          <div className="md:col-span-3">
            <h1 className="font-khinterference text-2xl md:text-5xl text-foreground/30 md:sticky md:top-[40vh] uppercase tracking-wider text-center md:text-left mb-8 md:mb-0">
              Manifesto
            </h1>
          </div>
          
          {/* Center content */}
          <div className="md:col-span-6 space-y-6 flex flex-col items-center text-center">
            <div className="space-y-2 md:space-y-0.5 max-w-md">
              <p className="font-khgiga text-sm md:text-xl">We live in a creator economy.</p>
              <p className="font-khgiga text-sm md:text-xl">An era of independence.</p>
            </div>

            <div className="space-y-2 md:space-y-0.5 max-w-md">
              <p className="font-khgiga text-sm md:text-xl">It's not just about content creation.</p>
              <p className="font-khgiga text-sm md:text-xl">It's a call to arms born out of frustration.</p>
              <p className="font-khgiga text-sm md:text-xl">A war-cry of a new generation.</p>
            </div>

            <div className="space-y-2 md:space-y-0.5 max-w-md">
              <p className="font-khgiga text-sm md:text-xl">It's rebellion against the hamster-wheel of old habits.</p>
              <p className="font-khgiga text-sm md:text-xl">Defined by old people.</p>
              <p className="font-khgiga text-sm md:text-xl">Outdated thinking.</p>
            </div>

            <div className="space-y-2 md:space-y-0.5 max-w-md">
              <p className="font-khgiga text-sm md:text-xl">"That's not a real job."</p>
              <p className="font-khgiga text-sm md:text-xl">"Grow up."</p>
              <p className="font-khgiga text-sm md:text-xl">"That's not the right way."</p>
              <p className="font-khgiga text-sm md:text-xl">They say.</p>
            </div>

            <div className="space-y-2 md:space-y-0.5 max-w-md">
              <p className="font-khgiga text-sm md:text-xl">But what if what they say,</p>
              <p className="font-khgiga text-sm md:text-xl">No longer works today?</p>
            </div>

            <div className="space-y-2 md:space-y-0.5 max-w-md">
              <p className="font-khgiga text-sm md:text-xl">What if their right way is wrong,</p>
              <p className="font-khgiga text-sm md:text-xl">And the new way is better.</p>
            </div>

            <div className="space-y-2 md:space-y-0.5 max-w-md">
              <p className="font-khgiga text-sm md:text-xl">What if one creator could replace an agency,</p>
              <p className="font-khgiga text-sm md:text-xl">Or beat the algorithm.</p>
            </div>

            <div className="space-y-2 md:space-y-0.5 max-w-md">
              <p className="font-khgiga text-sm md:text-xl">What if you started with the creator,</p>
              <p className="font-khgiga text-sm md:text-xl">Not the channel.</p>
            </div>

            <div className="space-y-2 md:space-y-0.5 max-w-md">
              <p className="font-khgiga text-sm md:text-xl">What if you build a community,</p>
              <p className="font-khgiga text-sm md:text-xl">Not just a company.</p>
            </div>

            <div className="space-y-2 md:space-y-0.5 max-w-md">
              <p className="font-khgiga text-sm md:text-xl">Leave the old habits to the old.</p>
              <p className="font-khgiga text-sm md:text-xl">It's time to create something new.</p>
            </div>
          </div>

          {/* Right side - OLD HABITS */}
          <div className="md:col-span-3">
            <div className="md:sticky md:top-[40vh] text-center md:text-right">
              <p className="font-khinterference text-2xl md:text-5xl text-foreground/30 leading-tight uppercase tracking-wider">
                <span className="md:hidden">Old Habits Are For The Old</span>
                <span className="hidden md:block">
                  Old<br />
                  Habits<br />
                  Are For<br />
                  The Old
                </span>
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AboutPage;
import { useState, useEffect } from "react";

interface QueryConfig<T> {
  queryKey: string;
  queryFn: () => Promise<T>;
  enabled?: boolean;
}

interface QueryResult<T> {
  data: T | null;
  isLoading: boolean;
  error: string | null;
}

const queryCache = new Map<string, any>();

export function useSupabaseQuery<T>({ 
  queryKey, 
  queryFn, 
  enabled = true 
}: QueryConfig<T>): QueryResult<T> {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (!enabled) {
        setIsLoading(false);
        return;
      }

      try {
        console.log(`Fetching data for ${queryKey}`);
        const result = await queryFn();
        
        if (isMounted) {
          queryCache.set(queryKey, result);
          setData(result);
          setError(null);
        }
      } catch (err) {
        console.error(`Error in ${queryKey}:`, err);
        if (isMounted) {
          const errorMessage = err instanceof Error ? err.message : 'An error occurred';
          setError(errorMessage);
          setData(null);
          queryCache.delete(queryKey);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [queryKey, enabled]);

  return { 
    data,
    isLoading, 
    error 
  };
}
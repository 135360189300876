import { H2 } from "@/components/Typography";
import { useAnalyticsData } from "./hooks/useAnalyticsData";
import { StatsCards } from "./StatsCards";
import { SignupsChart } from "./SignupsChart";

export const AdminAnalytics = () => {
  const { data, isLoading } = useAnalyticsData();

  return (
    <div className="space-y-6">
      <H2>Dashboard Analytics</H2>
      
      <StatsCards data={data} isLoading={isLoading} />
      
      {data && <SignupsChart data={data.dailySignups} />}
    </div>
  );
};
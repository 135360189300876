import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { MoreHorizontal, Shield, ShieldOff, Crown } from "lucide-react";
import { formatDate } from "@/lib/utils";
import type { Database } from "@/integrations/supabase/types";
import type { User, SortConfig } from "./useUsersSorting";

type MembershipTier = Database["public"]["Enums"]["membership_tier"];
type AdminRole = Database["public"]["Enums"]["admin_role"];

interface UsersTableProps {
  users: User[];
  onViewProfile: (user: User) => void;
  onUpdateMembership: (user: User, newTier: MembershipTier) => void;
  onResetInvites: (user: User) => void;
  onBanUser: (user: User) => void;
  onDeleteUser: (user: User) => void;
  onImpersonateUser: (user: User) => void;
  onMakeAdmin: (user: User) => void;
  onRemoveAdmin: (user: User) => void;
  sortConfig: SortConfig;
  onSort: (key: keyof User) => void;
}

export function UsersTable({
  users,
  onViewProfile,
  onUpdateMembership,
  onResetInvites,
  onBanUser,
  onDeleteUser,
  onImpersonateUser,
  onMakeAdmin,
  onRemoveAdmin,
  sortConfig,
  onSort,
}: UsersTableProps) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead 
            className="cursor-pointer"
            onClick={() => onSort('username')}
          >
            Username
            {sortConfig.key === 'username' && (
              <span>{sortConfig.direction === 'asc' ? ' ↑' : ' ↓'}</span>
            )}
          </TableHead>
          <TableHead>Role</TableHead>
          <TableHead>Membership</TableHead>
          <TableHead>Points</TableHead>
          <TableHead>Profile</TableHead>
          <TableHead>Invites</TableHead>
          <TableHead 
            className="cursor-pointer"
            onClick={() => onSort('created_at')}
          >
            Joined
            {sortConfig.key === 'created_at' && (
              <span>{sortConfig.direction === 'asc' ? ' ↑' : ' ↓'}</span>
            )}
          </TableHead>
          <TableHead>Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {users.map((user) => (
          <TableRow key={user.id}>
            <TableCell>
              <button 
                onClick={() => onViewProfile(user)}
                className="hover:underline focus:outline-none"
              >
                {user.username}
              </button>
            </TableCell>
            <TableCell>
              {user.role === 'owner' ? (
                <Badge variant="default" className="bg-purple-500">
                  <Crown className="w-3 h-3 mr-1" />
                  Owner
                </Badge>
              ) : user.is_admin ? (
                <Badge variant="default">
                  <Shield className="w-3 h-3 mr-1" />
                  Admin
                </Badge>
              ) : (
                user.account_type
              )}
            </TableCell>
            <TableCell>
              <Badge
                variant={
                  user.membership_tier === 'premium'
                    ? 'default'
                    : user.membership_tier === 'normal'
                    ? 'secondary'
                    : 'outline'
                }
              >
                {user.membership_tier}
              </Badge>
            </TableCell>
            <TableCell>{user.total_points}</TableCell>
            <TableCell>{user.profile_completion_percentage}%</TableCell>
            <TableCell>
              {user.available_invites}/{user.invite_quota}
            </TableCell>
            <TableCell>{formatDate(user.created_at)}</TableCell>
            <TableCell>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="h-8 w-8 p-0">
                    <MoreHorizontal className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem onClick={() => onViewProfile(user)}>
                    View Profile
                  </DropdownMenuItem>
                  
                  {user.role === 'owner' ? null : user.is_admin ? (
                    <DropdownMenuItem 
                      onClick={() => onRemoveAdmin(user)}
                      className="text-red-600"
                    >
                      <ShieldOff className="w-4 h-4 mr-2" />
                      Remove Admin
                    </DropdownMenuItem>
                  ) : (
                    <DropdownMenuItem onClick={() => onMakeAdmin(user)}>
                      <Shield className="w-4 h-4 mr-2" />
                      Make Admin
                    </DropdownMenuItem>
                  )}
                  
                  <DropdownMenuItem onClick={() => onImpersonateUser(user)}>
                    Login as User
                  </DropdownMenuItem>
                  
                  <DropdownMenuSeparator />
                  
                  <DropdownMenuItem onClick={() => onUpdateMembership(user, 'premium')}>
                    Make Premium
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => onUpdateMembership(user, 'normal')}>
                    Make Normal
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => onUpdateMembership(user, 'waitlist')}>
                    Move to Waitlist
                  </DropdownMenuItem>
                  
                  <DropdownMenuSeparator />
                  
                  <DropdownMenuItem onClick={() => onResetInvites(user)}>
                    Reset Invites
                  </DropdownMenuItem>
                  
                  <DropdownMenuItem 
                    onClick={() => onBanUser(user)}
                    className="text-red-600"
                  >
                    Ban User
                  </DropdownMenuItem>
                  
                  <DropdownMenuItem 
                    onClick={() => onDeleteUser(user)}
                    className="text-red-600"
                  >
                    Delete User
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
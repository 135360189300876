import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Badge } from "@/components/ui/badge";
import { BodySmall } from "@/components/Typography";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { ChevronDown, ChevronUp } from "lucide-react";

interface AuditLog {
  id: string;
  action_type: string;
  created_at: string;
  performed_by: string;
  action_data: any;
  performed_by_username: string | null;
  affected_user?: {
    username: string;
  };
}

export function AuditLogViewer() {
  const [searchTerm, setSearchTerm] = useState("");
  const [actionTypeFilter, setActionTypeFilter] = useState<string>("all");
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());

  const { data: logs, isLoading } = useQuery({
    queryKey: ['audit-logs'],
    queryFn: async () => {
      console.log("[AuditLogViewer] Fetching audit logs");
      const { data, error } = await supabase
        .from('user_admin_actions')
        .select(`
          *,
          affected_user:profiles(username)
        `)
        .order('created_at', { ascending: false })
        .limit(100);

      if (error) {
        console.error("[AuditLogViewer] Error fetching logs:", error);
        throw error;
      }
      console.log("[AuditLogViewer] Fetched logs:", data);
      return data as AuditLog[];
    },
  });

  const getActionColor = (actionType: string) => {
    switch (actionType.toLowerCase()) {
      case 'delete':
      case 'ban':
        return 'destructive';
      case 'update':
      case 'modify':
        return 'warning';
      case 'create':
      case 'add':
        return 'success';
      default:
        return 'secondary';
    }
  };

  const toggleRowExpansion = (id: string) => {
    const newExpandedRows = new Set(expandedRows);
    if (expandedRows.has(id)) {
      newExpandedRows.delete(id);
    } else {
      newExpandedRows.add(id);
    }
    setExpandedRows(newExpandedRows);
  };

  const formatActionData = (data: any) => {
    if (!data) return null;
    
    return Object.entries(data).map(([key, value]) => (
      <div key={key} className="mb-2">
        <BodySmall className="text-zinc-400 capitalize">{key.replace(/_/g, ' ')}</BodySmall>
        <BodySmall className="text-zinc-300">
          {typeof value === 'boolean' 
            ? value ? 'Yes' : 'No'
            : value?.toString() || 'N/A'}
        </BodySmall>
      </div>
    ));
  };

  const filteredLogs = logs?.filter(log => {
    const matchesSearch = 
      (log.performed_by_username?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
      log.action_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (log.affected_user?.username?.toLowerCase() || '').includes(searchTerm.toLowerCase());
    
    const matchesActionType = actionTypeFilter === 'all' || log.action_type.toLowerCase() === actionTypeFilter.toLowerCase();
    
    return matchesSearch && matchesActionType;
  });

  const uniqueActionTypes = [...new Set(logs?.map(log => log.action_type) ?? [])];

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Audit Logs</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="h-12 bg-zinc-900 animate-pulse rounded-lg" />
            ))}
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Audit Logs</CardTitle>
        <div className="flex gap-4 mt-4">
          <div className="flex-1">
            <Input
              placeholder="Search by admin, action type, or affected user..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full"
            />
          </div>
          <Select
            value={actionTypeFilter}
            onValueChange={setActionTypeFilter}
          >
            <SelectTrigger className="w-[200px]">
              <SelectValue placeholder="Filter by action" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All Actions</SelectItem>
              {uniqueActionTypes.map((type) => (
                <SelectItem key={type} value={type.toLowerCase()}>
                  {type}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[600px] pr-4">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[50px]"></TableHead>
                <TableHead>Action</TableHead>
                <TableHead>Performed By</TableHead>
                <TableHead>Affected User</TableHead>
                <TableHead>Date</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredLogs?.map((log) => (
                <>
                  <TableRow 
                    key={log.id}
                    className="cursor-pointer hover:bg-zinc-900"
                    onClick={() => toggleRowExpansion(log.id)}
                  >
                    <TableCell>
                      {expandedRows.has(log.id) ? (
                        <ChevronUp className="h-4 w-4" />
                      ) : (
                        <ChevronDown className="h-4 w-4" />
                      )}
                    </TableCell>
                    <TableCell>
                      <Badge variant={getActionColor(log.action_type)}>
                        {log.action_type}
                      </Badge>
                    </TableCell>
                    <TableCell>{log.performed_by_username || 'System'}</TableCell>
                    <TableCell>{log.affected_user?.username || 'N/A'}</TableCell>
                    <TableCell>{format(new Date(log.created_at), 'PPpp')}</TableCell>
                  </TableRow>
                  {expandedRows.has(log.id) && (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <div className="p-4 bg-zinc-950 rounded-md">
                          {formatActionData(log.action_data)}
                          <BodySmall className="text-zinc-500 mt-2">
                            Action ID: {log.id}
                          </BodySmall>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))}
            </TableBody>
          </Table>
        </ScrollArea>
      </CardContent>
    </Card>
  );
}
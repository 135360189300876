import { supabase } from "@/integrations/supabase/client";
import { InviteFormData } from "@/types/auth";

export const handleSignupOrSignin = async (
  data: InviteFormData,
  inviteCode?: string
) => {
  console.log("[handleSignupOrSignin] Starting process", {
    email: data.email,
    socialHandle: data.socialHandle,
    hasInviteCode: !!inviteCode
  });

  try {
    if (inviteCode) {
      console.log("[handleSignupOrSignin] Validating invite code:", inviteCode);
      const { data: invite, error: inviteError } = await supabase
        .from("invites")
        .select("inviter_id, status")
        .eq("invite_code", inviteCode)
        .single();

      if (inviteError) {
        console.error("[handleSignupOrSignin] Error checking invite code:", inviteError);
        throw new Error("Invalid invite code");
      }
      
      if (!invite.inviter_id) {
        console.error("[handleSignupOrSignin] No inviter_id found for code:", inviteCode);
        throw new Error("Invalid invite configuration");
      }

      console.log("[handleSignupOrSignin] Found valid invite:", {
        inviterId: invite.inviter_id,
        status: invite.status
      });
    }

    // First check if user exists
    console.log("[handleSignupOrSignin] Attempting sign in");
    const { data: existingUser, error: signInError } = await supabase.auth.signInWithPassword({
      email: data.email,
      password: data.password,
    });

    if (existingUser?.user) {
      console.log("[handleSignupOrSignin] User exists, signed in successfully", {
        userId: existingUser.user.id
      });
      return { data: existingUser, isExisting: true };
    }

    if (signInError && !signInError.message.includes("Invalid login credentials")) {
      console.error("[handleSignupOrSignin] Unexpected sign in error:", signInError);
      throw signInError;
    }

    // If user doesn't exist, proceed with signup
    console.log("[handleSignupOrSignin] User not found, proceeding with signup");
    const { data: authData, error: signupError } = await supabase.auth.signUp({
      email: data.email,
      password: data.password,
      options: {
        data: {
          username: data.socialHandle,
          invite_code: inviteCode,
          referral_source: inviteCode ? 'invite' : 'organic'
        },
        emailRedirectTo: `${window.location.origin}/waitlist-dashboard`
      }
    });

    if (signupError) {
      console.error("[handleSignupOrSignin] Signup error:", signupError);
      throw signupError;
    }

    console.log("[handleSignupOrSignin] Signup successful", {
      userId: authData?.user?.id,
      email: authData?.user?.email
    });

    if (inviteCode && authData?.user) {
      console.log("[handleSignupOrSignin] Creating referral connection");
      
      // Get the inviter_id from the invite code
      const { data: invite } = await supabase
        .from("invites")
        .select("inviter_id")
        .eq("invite_code", inviteCode)
        .single();

      if (invite?.inviter_id) {
        console.log("[handleSignupOrSignin] Linking referral", { 
          referrer: invite.inviter_id,
          referred: authData.user.id 
        });

        // Call the link_referral function to create the connection
        const { error: referralError } = await supabase
          .rpc('link_referral', {
            referrer_id: invite.inviter_id,
            referred_id: authData.user.id
          });

        if (referralError) {
          console.error("[handleSignupOrSignin] Error linking referral:", referralError);
        } else {
          console.log("[handleSignupOrSignin] Referral linked successfully");
        }
      }
    }

    return { data: authData, isExisting: false };
  } catch (error: any) {
    console.error("[handleSignupOrSignin] Unhandled error:", error);
    throw error;
  }
};
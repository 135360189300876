
import { DashboardHeader } from "@/components/DashboardHeader";
import { H1, BodySmall, H4 } from "@/components/Typography";
import { Footer } from "@/components/Footer";
import { Separator } from "@/components/ui/separator";

const TermsConditions = () => {
  return (
    <div className="min-h-screen bg-background flex flex-col">
      <DashboardHeader notifications={[]} queuePosition={0} showProfileButton={false} />
      
      <main className="container mx-auto px-4 py-8 flex-grow">
        <div className="max-w-4xl mx-auto">
          <H1 className="mb-4 text-center md:text-left">Terms & Conditions</H1>
          <BodySmall className="text-muted-foreground mb-8">(for Jungl ApS websites and apps)</BodySmall>
          
          <div className="prose prose-invert max-w-none space-y-4">
            <section>
              <BodySmall className="leading-relaxed">
                Effective Date: 17/02/2025
              </BodySmall>
              <BodySmall className="leading-relaxed mt-2">
                These Terms & Conditions ("Terms") govern your access to and use of the Jungl ApS
                websites, mobile applications, and digital services (collectively, the "Platform" or
                "Services"), provided by Jungl ApS ("Jungl", "we", "us"). By accessing or using our
                Services, you agree to be bound by these Terms, our Privacy Policy, and any additional
                policies incorporated herein by reference. If you do not agree to these Terms, please do not
                use our Services.
              </BodySmall>
            </section>
            
            <Separator className="my-4" />
            
            <section>
              <H4 className="mb-2 text-xl">1. Definitions</H4>
              <BodySmall className="leading-relaxed">
                For purposes of these Terms:
              </BodySmall>
              <ul className="list-disc pl-6 my-2">
                <li><BodySmall>&#8220;Account&#8221; means the personal account you create when registering for access to our Services.</BodySmall></li>
                <li><BodySmall>&#8220;Company&#8221; refers to any business or organization that uses the Platform for its services.</BodySmall></li>
                <li><BodySmall>&#8220;Influencer&#8221; means a user who registers to engage in promotional or content creation activities via the Platform.</BodySmall></li>
                <li><BodySmall>&#8220;Content&#8221; means any text, images, video, audio, or other material uploaded, posted, or otherwise transmitted via our Services.</BodySmall></li>
                <li><BodySmall>&#8220;Software&#8221; means any application or software provided as part of our Services.</BodySmall></li>
                <li><BodySmall>&#8220;Campaign&#8221; means any collaboration or project initiated on the Platform between an influencer and a company.</BodySmall></li>
                <li><BodySmall>&#8220;EULA&#8221; refers to the End-User License Agreement that governs your use of our Software, as integrated into these Terms.</BodySmall></li>
              </ul>
            </section>
            
            <Separator className="my-4" />
            
            <section>
              <H4 className="mb-2 text-xl">2. Acceptance of Terms</H4>
              <BodySmall className="leading-relaxed">
                By creating an Account, accessing, or using our Services, you confirm that you have read,
                understood, and agree to be bound by these Terms and any future modifications posted on our
                Platform. Your continued use of our Services constitutes your acceptance of these changes.
              </BodySmall>
            </section>
            
            <Separator className="my-4" />
            
            <section>
              <H4 className="mb-2 text-xl">3. License and End-User License Agreement (EULA)</H4>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">3.1 License Grant</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  Jungl ApS grants you a non-exclusive, non-transferable, revocable license to use our
                  Software solely in connection with your use of the Platform and in accordance with these
                  Terms.
                </BodySmall>
              </div>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">3.2 Restrictions</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  You agree not to:
                </BodySmall>
                <ul className="list-disc pl-6 my-2">
                  <li><BodySmall>Reverse engineer, decompile, or disassemble our Software.</BodySmall></li>
                  <li><BodySmall>Modify, adapt, or create derivative works from our Software.</BodySmall></li>
                  <li><BodySmall>Sell, sublicense, lease, or otherwise distribute any portion of our Software.</BodySmall></li>
                  <li><BodySmall>Remove, obscure, or alter any proprietary notices or labels in our Software.</BodySmall></li>
                  <li><BodySmall>Use the Software in any way that violates applicable laws or these Terms.</BodySmall></li>
                </ul>
              </div>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">3.3 Ownership and Termination</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  The Software, together with all intellectual property rights therein, remains the exclusive
                  property of Jungl ApS. This license is revocable, and Jungl ApS may terminate your access to
                  the Software immediately if you breach any of these Terms. Upon termination, you must
                  immediately cease all use of the Software and destroy all copies in your possession.
                </BodySmall>
              </div>
            </section>
            
            <Separator className="my-4" />
            
            <section>
              <H4 className="mb-2 text-xl">4. Use of the Platform and Services</H4>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">4.1 General Use</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  You agree to use our Services only for lawful purposes and in a manner that does not infringe
                  on the rights of, restrict, or inhibit anyone else's use and enjoyment of the Services.
                </BodySmall>
              </div>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">4.2 User-Generated Content</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  If you post or share content via our Platform, you agree that:
                </BodySmall>
                <ul className="list-disc pl-6 my-2">
                  <li><BodySmall>Your content will be subject to our community guidelines.</BodySmall></li>
                  <li><BodySmall>You grant Jungl ApS a non-exclusive, royalty-free, worldwide license to use, reproduce,
                  modify, and display such content in connection with our Services.</BodySmall></li>
                  <li><BodySmall>Jungl ApS reserves the right to remove or modify any content that violates our guidelines or
                  these Terms.</BodySmall></li>
                </ul>
              </div>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">4.3 Account Security</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  You are responsible for safeguarding your Account credentials. You agree to notify us
                  immediately of any unauthorized use of your Account. Jungl ApS will not be liable for any
                  losses arising from your failure to protect your Account information.
                </BodySmall>
              </div>
            </section>
            
            <Separator className="my-4" />
            
            <section>
              <H4 className="mb-2 text-xl">5. Payment and Subscription Terms</H4>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">5.1 Pricing and Fees</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  The Platform may offer subscription plans or fee-based services. All prices are indicated in
                  EUR exclusive of applicable taxes. Jungl ApS reserves the right to modify pricing and will
                  notify you in advance of any changes.
                </BodySmall>
              </div>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">5.2 Payment Methods</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  Payments for subscription services may be processed via credit/debit card or other payment
                  methods offered on the Platform. All payments are subject to the terms and conditions of our
                  payment service providers. Jungl ApS is not responsible for errors or delays caused by
                  payment processors.
                </BodySmall>
              </div>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">5.3 No Right of Withdrawal</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  Except as required by applicable law, once a subscription or service is activated, there is no
                  right of withdrawal.
                </BodySmall>
              </div>
            </section>
            
            <Separator className="my-4" />
            
            <section>
              <H4 className="mb-2 text-xl">6. Intellectual Property Rights</H4>
              <BodySmall className="leading-relaxed">
                All intellectual property rights in the Platform, Software, and any related materials are owned
                by Jungl ApS or its licensors. You agree not to use, copy, or reproduce any part of our
                intellectual property without prior written consent from Jungl ApS.
              </BodySmall>
            </section>
            
            <Separator className="my-4" />
            
            <section>
              <H4 className="mb-2 text-xl">7. Disclaimers and Limitation of Liability</H4>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">7.1 No Warranty</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  Our Services are provided "as is" and "as available," without warranties of any kind, either
                  express or implied, including but not limited to warranties of merchantability, fitness for a
                  particular purpose, or non-infringement.
                </BodySmall>
              </div>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">7.2 Limitation of Liability</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  In no event shall Jungl ApS be liable for any indirect, incidental, consequential, or punitive
                  damages, including but not limited to loss of profits or revenue, arising out of your use or
                  inability to use the Services—even if we have been advised of the possibility of such
                  damages.
                </BodySmall>
              </div>
              
              <div className="ml-2 mt-4">
                <H4 className="text-lg font-medium">7.3 Third-Party Content and Services</H4>
                <BodySmall className="mt-1 leading-relaxed">
                  Jungl ApS does not control and is not responsible for any third-party content or services
                  accessed via the Platform. Any disputes regarding third-party interactions must be resolved
                  directly with the relevant party.
                </BodySmall>
              </div>
            </section>
            
            <Separator className="my-4" />
            
            <section>
              <H4 className="mb-2 text-xl">8. Termination</H4>
              <BodySmall className="leading-relaxed">
                Jungl ApS may suspend or terminate your access to the Platform immediately if you breach
                these Terms or engage in behavior that is detrimental to our users, our business, or third
                parties. Upon termination, all licenses granted herein will immediately cease, and you must
                cease all use of our Services and destroy any copies of Software or related materials in your
                possession.
              </BodySmall>
            </section>
            
            <Separator className="my-4" />
            
            <section>
              <H4 className="mb-2 text-xl">9. Ethics and Acceptable Use</H4>
              <BodySmall className="leading-relaxed">
                Jungl ApS reserves the right to refuse or terminate access to our Services if, in our sole
                discretion, your use or any content you provide is ethically reprehensible, harmful, or in
                violation of any applicable laws or public policy. This includes, but is not limited to, content
                or campaigns that promote hate, violence, discrimination, or any illegal activities.
              </BodySmall>
            </section>
            
            <Separator className="my-4" />
            
            <section>
              <H4 className="mb-2 text-xl">10. Force Majeure</H4>
              <BodySmall className="leading-relaxed">
                Neither party shall be liable for any failure to perform its obligations where such failure is
                due to causes beyond its reasonable control, including but not limited to natural disasters,
                strikes, acts of war, or government action.
              </BodySmall>
            </section>
            
            <Separator className="my-4" />
            
            <section>
              <H4 className="mb-2 text-xl">11. Governing Law and Jurisdiction</H4>
              <BodySmall className="leading-relaxed">
                These Terms shall be governed by and construed in accordance with the laws of Denmark. Any disputes arising out of or in connection with these Terms
                shall be resolved in the competent courts of Copenhagen.
                Notwithstanding the foregoing, Jungl ApS reserves the right to bring legal proceedings in any
                jurisdiction where you reside or where the infringement of our rights occurred.
              </BodySmall>
            </section>
            
            <Separator className="my-4" />
            
            <section>
              <H4 className="mb-2 text-xl">12. Changes to These Terms</H4>
              <BodySmall className="leading-relaxed">
                Jungl ApS reserves the right to modify or update these Terms at any time. We will provide
                notice of any material changes by posting the updated Terms on our Platform or notifying
                you directly. Your continued use of our Services following any changes constitutes your
                acceptance of the revised Terms.
              </BodySmall>
            </section>
            
            <Separator className="my-4" />
            
            <section>
              <H4 className="mb-2 text-xl">13. Entire Agreement</H4>
              <BodySmall className="leading-relaxed">
                These Terms, together with the integrated EULA in Section 3 and our Privacy Policy,
                constitute the entire agreement between you and Jungl ApS regarding your use of our
                Services and supersede all prior or contemporaneous communications, agreements, or
                understandings, whether oral or written.
              </BodySmall>
            </section>
            
            <Separator className="my-4" />
            
            <section>
              <H4 className="mb-2 text-xl">14. Contact Information</H4>
              <BodySmall className="leading-relaxed">
                If you have any questions or concerns about these Terms or our Services, please contact us at:
              </BodySmall>
              <div className="my-3 pl-2">
                <BodySmall className="leading-relaxed">Jungl ApS</BodySmall>
                <BodySmall className="leading-relaxed">CVR: DK45319717</BodySmall>
                <BodySmall className="leading-relaxed">Address: Kronprinsessegade 54, 5. tv, 1306 København K, Danmark</BodySmall>
                <BodySmall className="leading-relaxed">Email: support@jungl.co</BodySmall>
              </div>
            </section>
            
            <div className="mt-8 pt-4 border-t border-border">
              <BodySmall className="text-muted-foreground">Last Revised: February 17, 2025</BodySmall>
            </div>
          </div>
        </div>
      </main>
      
      <Footer />
    </div>
  );
};

export default TermsConditions;

import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";

interface ReferralChain {
  id: string;
  root_referrer_id: string;
  depth: number;
  total_members: number;
}

interface ReferralChainViewerProps {
  userId: string;
}

export function ReferralChainViewer({ userId }: ReferralChainViewerProps) {
  const { data: chain, isLoading, error } = useQuery({
    queryKey: ['referral-chain', userId],
    queryFn: async () => {
      console.log("[ReferralChainViewer] Fetching chain for user:", userId);
      const { data, error } = await supabase
        .from('referral_chains')
        .select('*')
        .eq('root_referrer_id', userId)
        .maybeSingle();

      if (error) {
        console.error("[ReferralChainViewer] Error fetching chain:", error);
        throw error;
      }

      console.log("[ReferralChainViewer] Chain data:", data);
      return data as ReferralChain | null;
    },
  });

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <Skeleton className="h-6 w-32" />
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            <Skeleton className="h-4 w-24" />
            <Skeleton className="h-4 w-24" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <CardContent>
          <p className="text-destructive">Error loading referral chain</p>
        </CardContent>
      </Card>
    );
  }

  if (!chain) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Referral Chain</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <span className="text-zinc-400">Chain Depth</span>
              <span className="font-medium">0</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-zinc-400">Total Members</span>
              <span className="font-medium">0</span>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Referral Chain</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-2">
          <div className="flex justify-between items-center">
            <span className="text-zinc-400">Chain Depth</span>
            <span className="font-medium">{chain.depth}</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-zinc-400">Total Members</span>
            <span className="font-medium">{chain.total_members}</span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
import * as React from "react";
import { ResponsiveContainer, TooltipProps } from "recharts";
import { cn } from "@/lib/utils";

export interface ChartConfig {
  [key: string]: {
    label: string;
    color: string;
  }
}

interface ChartContainerProps extends React.ComponentPropsWithoutRef<typeof ResponsiveContainer> {
  config: ChartConfig;
}

export const ChartContainer = React.forwardRef<
  React.ElementRef<typeof ResponsiveContainer>,
  ChartContainerProps
>(({ children, config, className, ...props }, ref) => {
  return (
    <ResponsiveContainer ref={ref} width="100%" height={400} className={cn("", className)} {...props}>
      {children}
    </ResponsiveContainer>
  );
});
ChartContainer.displayName = "ChartContainer";

export const ChartTooltip = React.forwardRef<
  HTMLDivElement,
  TooltipProps<any, any>
>(({ active, payload, label, ...props }, ref) => {
  if (!active || !payload?.length) {
    return null;
  }

  return (
    <div
      ref={ref}
      className="rounded-lg border bg-zinc-900 p-2 shadow-md"
    >
      <p className="text-sm font-medium text-white">{label}</p>
      {payload.map((entry, index) => (
        <div key={index} className="flex items-center gap-2">
          <div
            className="h-2 w-2 rounded-full"
            style={{ backgroundColor: entry.color }}
          />
          <p className="text-sm text-zinc-400">
            {entry.name}: {entry.value}
          </p>
        </div>
      ))}
    </div>
  );
});
ChartTooltip.displayName = "ChartTooltip";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { InviteForm } from "@/components/auth/InviteForm";
import { supabase } from "@/integrations/supabase/client";
import { DashboardLoader } from "@/components/dashboard/DashboardLoader";
import { useToast } from "@/hooks/use-toast";
import { InviteHeader } from "@/components/invite/InviteHeader";
import { InviteCodeInput } from "@/components/invite/InviteCodeInput";
import { Navigation } from "@/components/Navigation";
import { CreatorGrid } from "@/components/CreatorGrid";
import { ApplicationForm } from "@/components/applications/ApplicationForm";
import { ApplicationFormData } from "@/types/applications";
import { Database } from "@/integrations/supabase/types";
import type { Json } from "@/integrations/supabase/types";

const InviteLanding = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [inviterProfile, setInviterProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [inviteCode, setInviteCode] = useState("");
  const [showApplicationForm, setShowApplicationForm] = useState(false);
  const { toast } = useToast();

  console.log("[InviteLanding] Component mounting, searchParams:", Object.fromEntries(searchParams));

  useEffect(() => {
    const code = searchParams.get("invite");
    console.log("[InviteLanding] Checking invite code from URL:", code);
    if (code) {
      console.log("[InviteLanding] Found invite code, fetching details:", code);
      fetchInviterDetails(code);
    }
  }, [searchParams]);

  const fetchInviterDetails = async (code: string) => {
    console.log("[InviteLanding] Starting to fetch inviter details for code:", code);
    setIsLoading(true);
    setError(null);
    
    try {
      console.log("[InviteLanding] Querying Supabase for invite:", code);
      const { data: invite, error: inviteError } = await supabase
        .from("invites")
        .select(`
          *,
          profiles:inviter_id (
            username,
            first_name,
            last_name,
            avatar_url,
            total_points
          )
        `)
        .eq("invite_code", code)
        .maybeSingle();

      console.log("[InviteLanding] Supabase query result:", { invite, inviteError });

      if (inviteError) {
        console.error("[InviteLanding] Error fetching invite:", inviteError);
        throw new Error("Error validating invite code");
      }

      if (!invite) {
        console.error("[InviteLanding] No invite found with code:", code);
        throw new Error("Invalid invite code. Please check and try again.");
      }

      console.log("[InviteLanding] Found valid invite with profile:", invite.profiles);
      setInviterProfile(invite.profiles);
      setShowApplicationForm(true);
    } catch (error: any) {
      console.error("[InviteLanding] Error in fetchInviterDetails:", error);
      setError(error.message);
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitCode = () => {
    console.log("[InviteLanding] Submitting invite code:", inviteCode);
    if (!inviteCode.trim()) {
      toast({
        title: "Error",
        description: "Please enter an invite code",
        variant: "destructive",
      });
      return;
    }
    setSearchParams({ invite: inviteCode.trim() });
  };

  const handleApplicationSubmit = async (data: ApplicationFormData) => {
    console.log("[InviteLanding] Submitting application:", data);
    setIsLoading(true);
    try {
      const inviteCode = searchParams.get("invite");
      
      type ApplicationInsert = Database["public"]["Tables"]["applications"]["Insert"];
      
      const applicationData: ApplicationInsert = {
        application_data: data as unknown as Json,
        status: "pending",
      };

      const { data: result, error } = await supabase
        .from("applications")
        .insert(applicationData)
        .select()
        .single();

      if (error) throw error;

      toast({
        title: "Application Submitted",
        description: "Your application has been submitted successfully. Please create an account to continue.",
      });

      // Redirect to signup with invite code
      window.location.href = `/login?invite=${inviteCode}`;
    } catch (error: any) {
      console.error("[InviteLanding] Error submitting application:", error);
      toast({
        title: "Error",
        description: "Failed to submit application. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <DashboardLoader />;
  }

  return (
    <div className="min-h-screen bg-black">
      <Navigation notifications={[]} rewards={{ points: 0, badges: [] }} />
      
      <div className="relative min-h-[calc(100vh-80px)]">
        {/* Background gradient */}
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-purple-500/10 via-transparent to-transparent opacity-50" />
        
        <div className="relative pt-12 pb-32">
          <div className="container max-w-3xl mx-auto px-4 mb-16">
            <InviteHeader />

            {error && (
              <div className="max-w-md mx-auto mb-8 p-4 bg-red-500/10 text-red-400 rounded-lg border border-red-500/20">
                {error}
              </div>
            )}

            <div className="flex justify-center">
              {!searchParams.get("invite") ? (
                <InviteCodeInput 
                  inviteCode={inviteCode}
                  setInviteCode={setInviteCode}
                  handleSubmitCode={handleSubmitCode}
                />
              ) : showApplicationForm ? (
                <ApplicationForm 
                  onSubmit={handleApplicationSubmit}
                  isLoading={isLoading}
                />
              ) : (
                <div className="w-full max-w-md bg-zinc-900/50 backdrop-blur-lg border border-zinc-800 rounded-lg p-6">
                  <InviteForm 
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    onSuccess={() => {}}
                    inviteCode={searchParams.get("invite") || ""}
                  />
                </div>
              )}
            </div>
          </div>

          {/* Add CreatorGrid component */}
          <div className="mt-12">
            <CreatorGrid />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteLanding;

import { supabase } from "@/integrations/supabase/client";
import { useSupabaseQuery } from "./useSupabaseQuery";
import type { ProfilesTable } from "@/integrations/supabase/types/profiles";

export const useProfileData = (userId: string) => {
  const { data, isLoading, error } = useSupabaseQuery<ProfilesTable['Row']>({
    queryKey: `profile-${userId}`,
    queryFn: async () => {
      console.log("Fetching profile for user:", userId);
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', userId)
        .maybeSingle();

      if (error) throw error;
      return data;
    },
    enabled: !!userId
  });

  return { 
    profile: data, 
    isLoading, 
    error 
  };
};

import { supabase } from "@/integrations/supabase/client";
import { useSupabaseQuery } from "./useSupabaseQuery";
import type { InviteWithProfile } from "@/integrations/supabase/types/invites";

export const useInvites = (userId: string) => {
  console.log("[useInvites] Initializing hook for user:", userId);

  const { data, isLoading, error } = useSupabaseQuery<InviteWithProfile[]>({
    queryKey: `invites-${userId}`,
    queryFn: async () => {
      console.log("[useInvites] Fetching invites for user:", userId);
      const { data, error } = await supabase
        .from('invites')
        .select(`
          *,
          profiles (
            username,
            avatar_url
          )
        `)
        .eq('inviter_id', userId)
        .order('created_at', { ascending: false });

      if (error) {
        console.error("[useInvites] Error fetching invites:", error);
        throw error;
      }

      console.log("[useInvites] Successfully fetched invites:", {
        count: data?.length,
        firstInviteCode: data?.[0]?.invite_code
      });
      
      return data;
    },
    enabled: !!userId
  });

  return { 
    invites: data || [], 
    isLoading, 
    error 
  };
};
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import type { Database } from "@/integrations/supabase/types";
import type { User } from "./useUsersSorting";

type MembershipTier = Database["public"]["Enums"]["membership_tier"];

export const useUserManagement = (refetch: () => void) => {
  const { toast } = useToast();

  const handleMakeAdmin = async (user: User) => {
    try {
      console.log("[UserManagement] Making user admin:", user.username);
      
      // Get current admin's session to verify permissions
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      
      if (sessionError || !session) {
        console.error("[UserManagement] Session error:", sessionError);
        throw new Error("No valid session found");
      }

      // First check if user is already an admin using maybeSingle() instead of single()
      const { data: existingAdmin, error: checkError } = await supabase
        .from('admin_users')
        .select('id')
        .eq('id', user.id)
        .maybeSingle();

      if (checkError) {
        console.error("[UserManagement] Error checking admin status:", checkError);
        throw checkError;
      }

      if (existingAdmin) {
        console.log("[UserManagement] User is already an admin");
        toast({
          title: "Already an Admin",
          description: `${user.username} is already an admin.`,
        });
        return;
      }

      // Insert new admin user
      const { error: insertError } = await supabase
        .from('admin_users')
        .insert({ id: user.id });

      if (insertError) {
        console.error("[UserManagement] Error inserting admin user:", insertError);
        throw insertError;
      }

      // Log the admin creation action
      await supabase.from('audit_logs').insert({
        action_type: 'make_admin',
        performed_by: session.user.id,
        action_data: {
          target_user_id: user.id,
          target_username: user.username,
        },
      });

      console.log("[UserManagement] Successfully made user admin");
      toast({
        title: "Success",
        description: `${user.username} is now an admin.`,
      });

      refetch();
    } catch (error) {
      console.error("[UserManagement] Error in handleMakeAdmin:", error);
      toast({
        title: "Error",
        description: "Failed to make user an admin. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleRemoveAdmin = async (user: User) => {
    try {
      console.log("[UserManagement] Removing admin access from:", user.username);
      const { error } = await supabase
        .from('admin_users')
        .delete()
        .eq('id', user.id);

      if (error) throw error;

      toast({
        title: "Admin access removed",
        description: `${user.username} is no longer an admin.`,
      });
      refetch();
    } catch (error) {
      console.error("[UserManagement] Error removing admin access:", error);
      toast({
        title: "Error",
        description: "Failed to remove admin access. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleDeleteUser = async (user: User) => {
    try {
      console.log("[UserManagement] Starting deletion process for user:", {
        userId: user.id,
        username: user.username
      });

      const { data: adminUser } = await supabase.auth.getUser();
      const { error: auditError } = await supabase
        .from('audit_logs')
        .insert({
          action_type: 'delete',
          performed_by: adminUser.user?.id,
          action_data: {
            deleted_user_id: user.id,
            deleted_username: user.username,
            timestamp: new Date().toISOString()
          }
        });

      if (auditError) {
        console.error("[UserManagement] Error creating audit log:", auditError);
        throw auditError;
      }

      const { error: deleteError } = await supabase
        .rpc('delete_user_completely', {
          user_id: user.id
        });

      if (deleteError) {
        console.error("[UserManagement] Error in delete_user_completely:", deleteError);
        throw deleteError;
      }

      toast({
        title: "User deleted",
        description: `${user.username} has been deleted successfully.`,
      });
      
      refetch();
    } catch (error) {
      console.error("[UserManagement] Error deleting user:", error);
      toast({
        title: "Error",
        description: "Failed to delete user. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleBanUser = async (user: User) => {
    try {
      console.log("[UserManagement] Banning user:", user.username);
      const { error } = await supabase
        .from('profiles')
        .update({ banned: true })
        .eq('id', user.id);

      if (error) throw error;

      toast({
        title: "User banned",
        description: `${user.username} has been banned.`,
      });
      refetch();
    } catch (error) {
      console.error("[UserManagement] Error banning user:", error);
      toast({
        title: "Error",
        description: "Failed to ban user. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleUpdateMembership = async (user: User, newTier: MembershipTier) => {
    try {
      console.log("[UserManagement] Updating membership for:", user.username, "to:", newTier);
      const { error } = await supabase
        .from('profiles')
        .update({ membership_tier: newTier })
        .eq('id', user.id);

      if (error) throw error;

      toast({
        title: "Membership updated",
        description: `${user.username}'s membership has been updated to ${newTier}.`,
      });
      refetch();
    } catch (error) {
      console.error("[UserManagement] Error updating membership:", error);
      toast({
        title: "Error",
        description: "Failed to update membership. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleImpersonateUser = async (user: User) => {
    try {
      console.log("[UserManagement] Impersonating user:", user.username);
      const { data: { user: adminUser } } = await supabase.auth.getUser();
      if (!adminUser) throw new Error('No admin user found');

      const { data, error } = await supabase.functions.invoke('handle-admin-impersonation', {
        body: { 
          admin_id: adminUser.id,
          target_user_id: user.id
        }
      });

      if (error) throw error;

      localStorage.setItem('admin_session', adminUser.id);

      const { error: signInError } = await supabase.auth.signInWithPassword({
        email: data.email,
        password: 'temp-password'
      });

      if (signInError) throw signInError;

      toast({
        title: "Impersonation active",
        description: `Now viewing as ${user.username}`,
      });
    } catch (error) {
      console.error("[UserManagement] Error impersonating user:", error);
      toast({
        title: "Error",
        description: "Failed to impersonate user. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleResetInvites = async (user: User) => {
    try {
      console.log("[UserManagement] Resetting invites for:", user.username);
      const { error } = await supabase
        .from('profiles')
        .update({ 
          available_invites: user.invite_quota,
        })
        .eq('id', user.id);

      if (error) throw error;

      toast({
        title: "Invites reset",
        description: `${user.username}'s invites have been reset to their quota.`,
      });
      refetch();
    } catch (error) {
      console.error("[UserManagement] Error resetting invites:", error);
      toast({
        title: "Error",
        description: "Failed to reset invites. Please try again.",
        variant: "destructive",
      });
    }
  };

  return {
    handleMakeAdmin,
    handleRemoveAdmin,
    handleUpdateMembership,
    handleBanUser,
    handleDeleteUser,
    handleImpersonateUser,
    handleResetInvites,
  };
};


import { useState, useEffect } from "react";
import { useProfileData } from "./useProfileData";
import { useQueuePosition } from "./useQueuePosition";
import { useNotifications } from "./useNotifications";
import { useInvites } from "./useInvites";

export const useDashboardData = (userId: string) => {
  console.log("[useDashboardData] Initializing with userId:", userId);

  const { profile, isLoading: profileLoading, error: profileError } = useProfileData(userId);
  const { queuePosition, totalCount, isLoading: queueLoading, error: queueError } = useQueuePosition(userId);
  const { notifications, isLoading: notificationsLoading, error: notificationsError } = useNotifications(userId);
  const { invites, isLoading: invitesLoading, error: invitesError } = useInvites(userId);

  const isLoading = profileLoading || queueLoading || notificationsLoading || invitesLoading;
  const error = profileError || queueError || notificationsError || invitesError;

  useEffect(() => {
    console.log("[useDashboardData] Data loading status:", {
      profileLoading,
      queueLoading,
      notificationsLoading,
      invitesLoading,
      error: error || 'none'
    });
  }, [profileLoading, queueLoading, notificationsLoading, invitesLoading, error]);

  return {
    profile,
    queuePosition,
    totalCount,
    notifications,
    invites,
    isLoading,
    error
  };
};

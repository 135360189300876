import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { useToast } from "@/hooks/use-toast";

interface PublicOnlyRouteProps {
  children: React.ReactNode;
}

export const PublicOnlyRoute = ({ children }: PublicOnlyRouteProps) => {
  const { user, isLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { toast } = useToast();

  useEffect(() => {
    console.log("[PublicOnlyRoute] Checking authentication status:", {
      isLoading,
      hasUser: !!user,
      currentPath: location.pathname
    });

    if (!isLoading && user) {
      console.log("[PublicOnlyRoute] Authenticated user attempting to access public route, redirecting to dashboard");
      toast({
        title: "Already authenticated",
        description: "You are already signed in.",
      });
      
      navigate("/app/dashboard", { replace: true });
    }
  }, [user, isLoading, navigate, location, toast]);

  // Show loading state
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-pulse text-foreground">Loading...</div>
      </div>
    );
  }

  // If not authenticated, render children
  return !user ? <>{children}</> : null;
};
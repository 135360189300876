import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { useToast } from "@/hooks/use-toast";

interface AuthGuardProps {
  children: React.ReactNode;
}

export const AuthGuard = ({ children }: AuthGuardProps) => {
  const { user, isLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { toast } = useToast();

  useEffect(() => {
    console.log("[AuthGuard] Checking authentication status:", {
      isLoading,
      hasUser: !!user,
      currentPath: location.pathname
    });

    if (!isLoading && !user) {
      console.log("[AuthGuard] Unauthorized access, redirecting to login");
      toast({
        title: "Authentication Required",
        description: "Please sign in to access this page.",
        variant: "destructive",
      });
      
      // Store the attempted URL for redirect after login
      navigate("/login", {
        replace: true,
        state: { from: location.pathname }
      });
    }
  }, [user, isLoading, navigate, location, toast]);

  // Show loading state
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-pulse text-foreground">Loading...</div>
      </div>
    );
  }

  // If authenticated, render children
  return user ? <>{children}</> : null;
};
import { DashboardHeader } from "@/components/DashboardHeader";
import { Toaster } from "@/components/ui/toaster";
import { AdminAnalytics } from "@/components/admin/analytics/AdminAnalytics";
import { UsersList } from "@/components/admin/UsersList";
import { AdminApplicationsList } from "@/components/admin/AdminApplicationsList";
import { WaitlistTable } from "@/components/admin/WaitlistTable";
import { AdminImpersonationBanner } from "@/components/admin/AdminImpersonationBanner";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { H2 } from "@/components/Typography";
import { useDashboardData } from "@/hooks/useDashboardData";
import { useAuth } from "@/contexts/AuthContext";

export default function AdminDashboard() {
  const { user } = useAuth();
  const { notifications, queuePosition } = useDashboardData(user?.id);

  return (
    <div className="min-h-screen bg-background text-foreground font-sans">
      <DashboardHeader 
        notifications={notifications}
        queuePosition={queuePosition}
      />
      <div className="p-4 md:p-8">
        <div className="max-w-7xl mx-auto space-y-8">
          {/* Analytics Dashboard */}
          <AdminAnalytics />
          
          {/* User Management Section */}
          <div className="space-y-4">
            <H2>User Management</H2>
            <Tabs defaultValue="users" className="space-y-4">
              <TabsList>
                <TabsTrigger value="users">Users</TabsTrigger>
                <TabsTrigger value="applications">Applications</TabsTrigger>
                <TabsTrigger value="waitlist">Waitlist</TabsTrigger>
              </TabsList>
              <TabsContent value="users" className="space-y-4">
                <UsersList />
              </TabsContent>
              <TabsContent value="applications" className="space-y-4">
                <AdminApplicationsList />
              </TabsContent>
              <TabsContent value="waitlist" className="space-y-4">
                <WaitlistTable />
              </TabsContent>
            </Tabs>
          </div>
        </div>
      </div>
      <Toaster />
      <AdminImpersonationBanner />
    </div>
  );
}
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Button } from "../ui/button";
import { Menu } from "lucide-react";
import { Link } from "react-router-dom";
import { NavigationItems } from "./NavigationItems";

interface MobileNavigationProps {
  notifications: any[];
  rewards: {
    points: number;
    badges: string[];
  };
  isAuthenticated: boolean;
  onSignOut: () => Promise<void>;
  onSignIn: () => void;
}

export const MobileNavigation = ({
  notifications,
  rewards,
  isAuthenticated,
  onSignOut,
  onSignIn
}: MobileNavigationProps) => {
  return (
    <div className="flex md:hidden flex-1 justify-end">
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="ghost" size="icon">
            <Menu className="h-6 w-6" />
          </Button>
        </SheetTrigger>
        <SheetContent side="right" className="w-[300px] sm:w-[400px]">
          <div className="flex flex-col gap-4 mt-8">
            <Link 
              to="/about"
              className="text-foreground text-lg font-khinterference hover:text-foreground/80"
            >
              Creators
            </Link>
            <NavigationItems
              notifications={notifications}
              rewards={rewards}
              isAuthenticated={isAuthenticated}
              onSignOut={onSignOut}
              onSignIn={onSignIn}
            />
          </div>
        </SheetContent>
      </Sheet>
    </div>
  );
};
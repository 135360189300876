import { User } from "lucide-react";

interface ProfileHeaderProps {
  username: string;
  firstName?: string | null;
  lastName?: string | null;
  avatarUrl?: string | null;
}

export const ProfileHeader = ({ username, firstName, lastName, avatarUrl }: ProfileHeaderProps) => {
  return (
    <div className="flex items-center gap-4">
      <div className="w-16 h-16 rounded-full bg-zinc-800 flex items-center justify-center">
        {avatarUrl ? (
          <img
            src={avatarUrl}
            alt="Profile"
            className="w-full h-full rounded-full object-cover"
          />
        ) : (
          <User className="h-8 w-8 text-zinc-400" />
        )}
      </div>
      <div>
        <h3 className="text-lg font-medium text-white">@{username}</h3>
        <p className="text-zinc-400">
          {firstName && lastName
            ? `${firstName} ${lastName}`
            : "Add your name"}
        </p>
      </div>
    </div>
  );
};
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { ArrowRight } from "lucide-react";

interface InviteCodeInputProps {
  inviteCode: string;
  setInviteCode: (code: string) => void;
  handleSubmitCode: () => void;
}

export const InviteCodeInput = ({ inviteCode, setInviteCode, handleSubmitCode }: InviteCodeInputProps) => {
  return (
    <Card className="w-full max-w-md bg-zinc-900/50 backdrop-blur-lg border-zinc-800">
      <CardHeader className="space-y-2 text-center">
        <CardTitle className="text-2xl font-semibold text-white">
          Join Jungle
        </CardTitle>
        <CardDescription className="text-white">
          Enter your invite code to get started
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="flex gap-2">
          <Input
            value={inviteCode}
            onChange={(e) => setInviteCode(e.target.value)}
            placeholder="Enter your invite code"
            className="bg-zinc-800/50 border-zinc-700 text-white placeholder:text-white/40"
          />
          <Button 
            onClick={handleSubmitCode}
            className="bg-white hover:bg-white/90 text-black"
          >
            <ArrowRight className="h-4 w-4 text-black" />
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
import { useState, useMemo } from 'react';
import type { Database } from "@/integrations/supabase/types";

type MembershipTier = Database["public"]["Enums"]["membership_tier"];
type AdminRole = Database["public"]["Enums"]["admin_role"];

export interface User {
  id: string;
  username: string;
  created_at: string;
  account_type: string;
  membership_tier: MembershipTier;
  first_name: string | null;
  last_name: string | null;
  bio: string | null;
  total_points: number;
  profile_completion_percentage: number;
  invite_quota: number;
  available_invites: number;
  is_admin?: boolean;
  role?: AdminRole;
}

export type SortConfig = {
  key: keyof User;
  direction: 'asc' | 'desc';
};

export const useUsersSorting = (initialUsers: User[]) => {
  const [sortConfig, setSortConfig] = useState<SortConfig>({ 
    key: 'created_at', 
    direction: 'desc' 
  });

  const sortUsers = (users: User[]) => {
    return [...users].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (aValue === null) return 1;
      if (bValue === null) return -1;
      
      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const requestSort = (key: keyof User) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const sortedUsers = useMemo(() => sortUsers(initialUsers), [initialUsers, sortConfig]);

  return { sortedUsers, sortConfig, requestSort };
};
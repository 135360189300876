import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { formatDate } from "@/lib/utils";
import { ReferralChainViewer } from "./ReferralChainViewer";
import type { Database } from "@/integrations/supabase/types";

type MembershipTier = Database["public"]["Enums"]["membership_tier"];

interface User {
  id: string;
  username: string;
  first_name: string | null;
  last_name: string | null;
  membership_tier: MembershipTier;
  created_at: string;
  account_type: string;
  bio: string | null;
  total_points: number;
  profile_completion_percentage: number;
}

interface UserProfileModalProps {
  user: User | null;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function UserProfileModal({ user, open, onOpenChange }: UserProfileModalProps) {
  if (!user) return null;

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>User Profile</DialogTitle>
        </DialogHeader>
        
        <div className="grid gap-4">
          {/* Basic Info Card */}
          <Card>
            <CardHeader>
              <CardTitle>Basic Information</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid gap-2">
                <div className="flex justify-between items-center">
                  <span className="text-zinc-400">Username</span>
                  <span className="font-medium">@{user.username}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-zinc-400">Name</span>
                  <span className="font-medium">
                    {user.first_name && user.last_name 
                      ? `${user.first_name} ${user.last_name}`
                      : "Not set"}
                  </span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-zinc-400">Account Type</span>
                  <Badge>{user.account_type}</Badge>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-zinc-400">Membership</span>
                  <Badge variant={
                    user.membership_tier === 'premium' ? 'default' :
                    user.membership_tier === 'normal' ? 'secondary' :
                    'outline'
                  }>
                    {user.membership_tier}
                  </Badge>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Engagement Card */}
          <Card>
            <CardHeader>
              <CardTitle>Engagement Metrics</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid gap-2">
                <div className="flex justify-between items-center">
                  <span className="text-zinc-400">Profile Completion</span>
                  <span className="font-medium">{user.profile_completion_percentage}%</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-zinc-400">Total Points</span>
                  <span className="font-medium">{user.total_points}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-zinc-400">Member Since</span>
                  <span className="font-medium">{formatDate(user.created_at)}</span>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Referral Chain */}
          <ReferralChainViewer userId={user.id} />

          {/* Bio Card */}
          {user.bio && (
            <Card>
              <CardHeader>
                <CardTitle>Bio</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-zinc-400">{user.bio}</p>
              </CardContent>
            </Card>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://qwrtzbicgmhtilxtskmo.supabase.co";
const SUPABASE_ANON_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF3cnR6YmljZ21odGlseHRza21vIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzU5ODk5MTQsImV4cCI6MjA1MTU2NTkxNH0.0jnQcvlMYYQxtGd9vkBPvaDF3bZ0gxVXfIkSNwHESSw";

export const supabase = createClient<Database>(
  SUPABASE_URL,
  SUPABASE_ANON_KEY,
  {
    auth: {
      persistSession: true,
      autoRefreshToken: true,
      detectSessionInUrl: true,
      storageKey: 'supabase.auth.token',
      storage: window.localStorage
    },
    global: {
      headers: {
        'Content-Type': 'application/json',
        'Prefer': 'return=minimal'
      }
    }
  }
);
import { useState } from "react";
import { DashboardError } from "../dashboard/DashboardError";
import { DashboardLoader } from "../dashboard/DashboardLoader";
import { UserProfileModal } from "./UserProfileModal";
import { UsersTable } from "./users/UsersTable";
import { useUsers } from "./users/useUsers";
import { AuditLogViewer } from "./AuditLogViewer";
import { UsersSearch } from "./users/UsersSearch";
import { UsersFilter } from "./users/UsersFilter";
import { useUsersSorting } from "./users/useUsersSorting";
import { useUserManagement } from "./users/useUserManagement";
import type { Database } from "@/integrations/supabase/types";

type MembershipTier = Database["public"]["Enums"]["membership_tier"];

interface User {
  id: string;
  username: string;
  created_at: string;
  account_type: string;
  membership_tier: MembershipTier;
  first_name: string | null;
  last_name: string | null;
  bio: string | null;
  total_points: number;
  profile_completion_percentage: number;
  invite_quota: number;
  available_invites: number;
  is_admin?: boolean;
}

export function UsersList() {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState<{ tier?: MembershipTier | null }>({});
  
  const { data: users, isLoading, error, refetch } = useUsers();
  const userManagement = useUserManagement(refetch);

  const filteredUsers = users?.filter(user => {
    const matchesSearch = user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (user.first_name && user.first_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.last_name && user.last_name.toLowerCase().includes(searchQuery.toLowerCase()));
    
    const matchesTier = !filter.tier || user.membership_tier === filter.tier;
    
    return matchesSearch && matchesTier;
  }) || [];

  const { sortedUsers, sortConfig, requestSort } = useUsersSorting(filteredUsers);

  const handleViewProfile = (user: User) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  if (error) {
    console.error("[UsersList] Rendering error state:", error);
    return <DashboardError message="Error loading users. Please try again later." />;
  }

  if (isLoading) {
    return <DashboardLoader />;
  }

  if (!users || users.length === 0) {
    return (
      <div className="p-4 text-center text-muted-foreground">
        No users found
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="space-y-6">
        <div className="flex gap-4 items-center">
          <div className="flex-1">
            <UsersSearch onSearch={setSearchQuery} />
          </div>
          <UsersFilter onFilterChange={setFilter} />
        </div>

        <UsersTable
          users={sortedUsers}
          onViewProfile={handleViewProfile}
          onUpdateMembership={userManagement.handleUpdateMembership}
          onResetInvites={userManagement.handleResetInvites}
          onBanUser={userManagement.handleBanUser}
          onDeleteUser={userManagement.handleDeleteUser}
          onImpersonateUser={userManagement.handleImpersonateUser}
          onMakeAdmin={userManagement.handleMakeAdmin}
          onRemoveAdmin={userManagement.handleRemoveAdmin}
          sortConfig={sortConfig}
          onSort={requestSort}
        />

        <AuditLogViewer />

        <UserProfileModal
          user={selectedUser}
          open={isModalOpen}
          onOpenChange={setIsModalOpen}
        />
      </div>
    </div>
  );
}

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Toaster } from "@/components/ui/toaster";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Index from "@/pages/Index";
import AboutPage from "@/pages/AboutPage";
import LoginPage from "@/pages/LoginPage";
import ProfilePage from "@/pages/ProfilePage";
import WaitlistDashboard from "@/pages/WaitlistDashboard";
import AdminDashboard from "@/pages/AdminDashboard";
import InviteLanding from "@/pages/InviteLanding";
import PrivacyPolicy from "@/pages/PrivacyPolicy";
import TermsConditions from "@/pages/TermsConditions";
import SupportPage from "@/pages/SupportPage";
import { AuthProvider } from "@/contexts/AuthContext";
import { AuthGuard } from "@/components/auth/AuthGuard";
import { AdminGuard } from "@/components/auth/AdminGuard";
import { PublicOnlyRoute } from "@/components/auth/PublicOnlyRoute";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<Index />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsConditions />} />
            <Route path="/support" element={<SupportPage />} />
            <Route 
              path="/login" 
              element={
                <PublicOnlyRoute>
                  <LoginPage />
                </PublicOnlyRoute>
              } 
            />
            <Route path="/invite/:code" element={<InviteLanding />} />

            {/* Protected user routes */}
            <Route path="/app" element={<AuthGuard><Navigate to="/app/dashboard" replace /></AuthGuard>} />
            <Route 
              path="/app/dashboard" 
              element={
                <AuthGuard>
                  <WaitlistDashboard />
                </AuthGuard>
              } 
            />
            <Route 
              path="/app/profile" 
              element={
                <AuthGuard>
                  <ProfilePage />
                </AuthGuard>
              } 
            />

            {/* Protected admin routes */}
            <Route path="/app/admin" element={<AdminGuard><Navigate to="/app/admin/dashboard" replace /></AdminGuard>} />
            <Route 
              path="/app/admin/dashboard" 
              element={
                <AdminGuard>
                  <AdminDashboard />
                </AdminGuard>
              } 
            />

            {/* Redirect old routes to new structure */}
            <Route path="/waitlist-dashboard" element={<Navigate to="/app/dashboard" replace />} />
            <Route path="/profile" element={<Navigate to="/app/profile" replace />} />

            {/* Catch all route */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
          <Toaster />
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
}

export default App;

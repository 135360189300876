import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";

export const useUsers = () => {
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      console.log("[useUsers] Executing query");
      
      // First get all users
      const { data: profiles, error: profilesError } = await supabase
        .from("profiles")
        .select("*")
        .order("created_at", { ascending: false });

      if (profilesError) {
        console.error("[useUsers] Error fetching profiles:", profilesError);
        throw profilesError;
      }

      // Then get admin roles
      const { data: adminUsers, error: adminError } = await supabase
        .from("admin_users")
        .select("id, role")
        .eq("active", true);

      if (adminError) {
        console.error("[useUsers] Error fetching admin users:", adminError);
        throw adminError;
      }

      // Combine the data
      const usersWithRoles = profiles.map(profile => {
        const adminUser = adminUsers?.find(admin => admin.id === profile.id);
        return {
          ...profile,
          role: adminUser?.role,
          is_admin: !!adminUser
        };
      });

      console.log("[useUsers] Fetched users:", usersWithRoles);
      return usersWithRoles;
    },
  });

  return {
    data: data || [],
    isLoading,
    error,
    refetch
  };
};
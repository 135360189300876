import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Skeleton } from "@/components/ui/skeleton";
import { format } from "date-fns";
import { useToast } from "@/hooks/use-toast";

interface WaitlistUser {
  id: string;
  username: string;
  created_at: string;
  position: number;
  total_referrals: number;
}

export const WaitlistTable = () => {
  const { toast } = useToast();

  const { data: waitlistUsers, isLoading } = useQuery({
    queryKey: ['waitlist-users'],
    queryFn: async () => {
      console.log("[WaitlistTable] Fetching waitlist users");
      
      // First get all admin users IDs
      const { data: adminUsers, error: adminError } = await supabase
        .from('admin_users')
        .select('id');
      
      if (adminError) {
        console.error("[WaitlistTable] Error fetching admin users:", adminError);
        throw adminError;
      }

      const adminIds = adminUsers?.map(admin => admin.id) || [];
      console.log("[WaitlistTable] Admin IDs:", adminIds);
      
      // Then get profiles with waitlist tier and their metrics
      const { data: profiles, error } = await supabase
        .from('profiles')
        .select(`
          id,
          username,
          created_at,
          total_referrals:referrals!referrals_referrer_id_fkey(count),
          waitlist_metrics!inner(position)
        `)
        .eq('membership_tier', 'waitlist')
        .not('id', 'in', `(${adminIds.length > 0 ? adminIds.join(',') : '00000000-0000-0000-0000-000000000000'})`)
        .order('created_at', { ascending: true });

      if (error) {
        console.error("[WaitlistTable] Error fetching waitlist:", error);
        toast({
          title: "Error",
          description: "Failed to load waitlist data. Please try again.",
          variant: "destructive",
        });
        throw error;
      }

      console.log("[WaitlistTable] Raw profiles data:", profiles);

      // Transform the data to match the WaitlistUser interface
      const transformedData = profiles?.map(user => ({
        id: user.id,
        username: user.username,
        created_at: user.created_at,
        position: user.waitlist_metrics?.[0]?.position || 0,
        total_referrals: user.total_referrals?.[0]?.count || 0
      })) || [];

      console.log("[WaitlistTable] Transformed waitlist users:", transformedData);
      return transformedData;
    },
  });

  if (isLoading) {
    return (
      <div className="space-y-2">
        {[...Array(5)].map((_, i) => (
          <Skeleton key={i} className="h-12 w-full" />
        ))}
      </div>
    );
  }

  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Position</TableHead>
            <TableHead>Username</TableHead>
            <TableHead>Joined</TableHead>
            <TableHead>Referrals</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {waitlistUsers?.map((user) => (
            <TableRow key={user.id}>
              <TableCell>#{user.position || "N/A"}</TableCell>
              <TableCell>{user.username}</TableCell>
              <TableCell>
                {format(new Date(user.created_at), "MMM d, yyyy")}
              </TableCell>
              <TableCell>{user.total_referrals}</TableCell>
            </TableRow>
          ))}
          {!waitlistUsers?.length && (
            <TableRow>
              <TableCell colSpan={4} className="text-center py-4">
                No users in waitlist
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
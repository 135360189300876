import { DateRange } from "react-day-picker";
import { addDays, differenceInDays, isWithinInterval, startOfDay } from "date-fns";

interface SignupData {
  date: string;
  count: number;
}

export const useTrendCalculation = (data: SignupData[], dateRange: DateRange | undefined) => {
  if (!dateRange?.from || !dateRange?.to) {
    return { trend: 0, trendText: "No change" };
  }

  const currentPeriodDays = differenceInDays(dateRange.to, dateRange.from) + 1;
  const previousPeriodStart = addDays(dateRange.from, -currentPeriodDays);

  const currentPeriodData = data.filter(item => {
    const itemDate = startOfDay(new Date(item.date));
    return isWithinInterval(itemDate, {
      start: startOfDay(dateRange.from),
      end: startOfDay(dateRange.to),
    });
  });

  const previousPeriodData = data.filter(item => {
    const itemDate = startOfDay(new Date(item.date));
    return isWithinInterval(itemDate, {
      start: startOfDay(previousPeriodStart),
      end: startOfDay(addDays(dateRange.from, -1)),
    });
  });

  const currentTotal = currentPeriodData.reduce((sum, item) => sum + item.count, 0);
  const previousTotal = previousPeriodData.reduce((sum, item) => sum + item.count, 0);

  if (previousTotal === 0) {
    return {
      trend: currentTotal > 0 ? 100 : 0,
      trendText: currentTotal > 0 ? "up by 100%" : "No change"
    };
  }

  const trend = ((currentTotal - previousTotal) / previousTotal) * 100;
  const trendText = trend === 0 
    ? "No change"
    : trend > 0 
      ? `up by ${trend.toFixed(1)}%` 
      : `down by ${Math.abs(trend).toFixed(1)}%`;

  return { trend, trendText };
};
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import { ChartTooltip } from "@/components/ui/chart";

interface ChartData {
  date: string;
  signups: number;
}

interface ChartContentProps {
  data: ChartData[];
}

export const ChartContent = ({ data }: ChartContentProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={data}>
        <CartesianGrid 
          horizontal={true}
          vertical={false} 
          stroke="hsl(var(--muted))" 
          opacity={0.3} 
        />
        <XAxis
          dataKey="date"
          tickLine={false}
          axisLine={false}
          tickMargin={8}
          stroke="hsl(var(--muted-foreground))"
          fontSize={12}
          style={{ fill: "hsl(var(--foreground))" }}
        />
        <YAxis
          tickLine={false}
          axisLine={false}
          tickMargin={8}
          stroke="hsl(var(--muted-foreground))"
          fontSize={12}
          style={{ fill: "hsl(var(--foreground))" }}
        />
        <Tooltip content={<ChartTooltip />} />
        <Line
          type="monotone"
          dataKey="signups"
          stroke="hsl(var(--primary))"
          strokeWidth={2}
          dot={{
            fill: "hsl(var(--primary))",
            strokeWidth: 0,
          }}
          activeDot={{
            r: 6,
            fill: "hsl(var(--primary))",
            strokeWidth: 0,
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
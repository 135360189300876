import { Card } from "@/components/ui/card";
import { H2, BodyLarge } from "@/components/Typography";
import { Skeleton } from "@/components/ui/skeleton";

interface StatsCardsProps {
  data: {
    totalUsers: number;
    waitlistUsers: number;
    normalUsers: number;
    premiumUsers: number;
  } | null;
  isLoading: boolean;
}

export const StatsCards = ({ data, isLoading }: StatsCardsProps) => {
  if (isLoading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {[1, 2, 3, 4].map((i) => (
          <Card key={i} className="p-6">
            <Skeleton className="h-4 w-24 mb-2" />
            <Skeleton className="h-8 w-16" />
          </Card>
        ))}
      </div>
    );
  }

  if (!data) return null;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      <Card className="p-6">
        <BodyLarge>Total Users</BodyLarge>
        <H2>{data.totalUsers.toLocaleString()}</H2>
        <span className="text-sm text-muted-foreground">
          Excluding admins
        </span>
      </Card>
      
      <Card className="p-6">
        <BodyLarge>Waitlist Users</BodyLarge>
        <H2>{data.waitlistUsers.toLocaleString()}</H2>
      </Card>
      
      <Card className="p-6">
        <BodyLarge>Normal Users</BodyLarge>
        <H2>{data.normalUsers.toLocaleString()}</H2>
      </Card>
      
      <Card className="p-6">
        <BodyLarge>Premium Users</BodyLarge>
        <H2>{data.premiumUsers.toLocaleString()}</H2>
      </Card>
    </div>
  );
};
import { Button } from "@/components/ui/button";
import { useForm } from "react-hook-form";
import { useToast } from "@/hooks/use-toast";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { FormField } from "./FormField";
import { handleSignupOrSignin } from "@/utils/authHelpers";
import { InviteFormData, InviteFormProps } from "@/types/auth";

export const InviteForm = ({ isLoading, setIsLoading, onSuccess, inviteCode }: InviteFormProps) => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm<InviteFormData>();
  const { toast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      console.log("Auth state changed in InviteForm:", event, !!session);
      if (session) {
        navigate('/waitlist-dashboard');
      }
    });

    return () => subscription.unsubscribe();
  }, [navigate]);

  const onSubmit = async (data: InviteFormData) => {
    console.log("Form submitted with data:", { ...data, inviteCode });
    setIsLoading(true);
    
    try {
      const { isExisting } = await handleSignupOrSignin(data, inviteCode);
      
      toast({
        title: isExisting ? "Welcome back!" : "Welcome to the waitlist! 🎉",
        description: isExisting 
          ? "Redirecting you to your dashboard..."
          : "Check your dashboard to see your position and invite others.",
      });

      onSuccess();
      reset();
    } catch (error: any) {
      console.error("Error during form submission:", error);
      toast({
        title: "Error",
        description: error.message || "Something went wrong. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <FormField
        name="email"
        type="email"
        placeholder="Enter your email"
        register={register}
        error={errors.email}
        validation={{
          required: "Email is required",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address"
          }
        }}
      />
      <FormField
        name="password"
        type="password"
        placeholder="Choose a password"
        register={register}
        error={errors.password}
        validation={{
          required: "Password is required",
          minLength: {
            value: 6,
            message: "Password must be at least 6 characters"
          }
        }}
      />
      <FormField
        name="socialHandle"
        type="text"
        placeholder="Your Instagram or YouTube handle"
        register={register}
        error={errors.socialHandle}
        validation={{
          required: "Social handle is required",
          minLength: {
            value: 2,
            message: "Social handle must be at least 2 characters"
          }
        }}
      />
      <Button
        type="submit"
        className="w-full bg-zinc-800 text-white hover:bg-zinc-700"
        disabled={isLoading}
      >
        {isLoading ? "Processing..." : "Join Waitlist"}
      </Button>
    </form>
  );
};
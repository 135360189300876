import { cn } from "@/lib/utils";

interface TypographyProps {
  children: React.ReactNode;
  className?: string;
}

export const H1 = ({ children, className }: TypographyProps) => (
  <h1 className={cn("font-khinterference text-h1", className)}>{children}</h1>
);

export const H2 = ({ children, className }: TypographyProps) => (
  <h2 className={cn("font-khinterference text-h2", className)}>{children}</h2>
);

export const H3 = ({ children, className }: TypographyProps) => (
  <h3 className={cn("font-khinterference text-h3", className)}>{children}</h3>
);

export const H4 = ({ children, className }: TypographyProps) => (
  <h4 className={cn("font-khinterference text-h4", className)}>{children}</h4>
);

export const H5 = ({ children, className }: TypographyProps) => (
  <h5 className={cn("font-khinterference text-xl", className)}>{children}</h5>
);

export const BodyLarge = ({ children, className }: TypographyProps) => (
  <p className={cn("font-khteka text-body-lg", className)}>{children}</p>
);

export const Body = ({ children, className }: TypographyProps) => (
  <p className={cn("font-khteka text-body", className)}>{children}</p>
);

export const BodySmall = ({ children, className }: TypographyProps) => (
  <p className={cn("font-khteka text-body-sm", className)}>{children}</p>
);

export const DisplayLarge = ({ children, className }: TypographyProps) => (
  <div className={cn("font-khteka text-display-lg", className)}>{children}</div>
);

export const Display = ({ children, className }: TypographyProps) => (
  <div className={cn("font-khteka text-display", className)}>{children}</div>
);

export const DisplaySmall = ({ children, className }: TypographyProps) => (
  <div className={cn("font-khteka text-display-sm", className)}>{children}</div>
);

interface QueueCounterProps {
  queuePosition: number;
}

export const QueueCounter = ({ queuePosition }: QueueCounterProps) => {
  // Don't show counter for admin users
  if (queuePosition === 0) {
    return null;
  }

  return (
    <div className="text-foreground text-lg hidden md:block font-khinterference">
      Queue Position: #{queuePosition}
    </div>
  );
};

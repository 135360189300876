import { DateRange } from "react-day-picker";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { CustomDateRangePicker } from "@/components/ui/custom-date-range-picker";

interface ChartHeaderProps {
  dateRange: DateRange | undefined;
  onDateRangeChange: (range: DateRange | undefined) => void;
  isCumulative: boolean;
  onCumulativeChange: (value: boolean) => void;
}

export const ChartHeader = ({
  dateRange,
  onDateRangeChange,
  isCumulative,
  onCumulativeChange,
}: ChartHeaderProps) => {
  return (
    <div className="flex items-center gap-8">
      <div className="flex items-center space-x-2">
        <Switch
          id="cumulative-mode"
          checked={isCumulative}
          onCheckedChange={onCumulativeChange}
        />
        <Label htmlFor="cumulative-mode">Show cumulative</Label>
      </div>
      <CustomDateRangePicker
        date={dateRange}
        onChange={onDateRangeChange}
      />
    </div>
  );
};
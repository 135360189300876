import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { ApplicationReview } from "./ApplicationReview";
import { Card } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Badge } from "@/components/ui/badge";

export function AdminApplicationsList() {
  const [applications, setApplications] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchApplications = async () => {
    try {
      console.log('[AdminApplicationsList] Fetching applications');
      const { data, error } = await supabase
        .from('applications')
        .select(`
          *,
          profiles:user_id (
            username,
            first_name,
            last_name
          )
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;
      
      console.log('[AdminApplicationsList] Applications fetched:', data);
      setApplications(data);
    } catch (error) {
      console.error('[AdminApplicationsList] Error fetching applications:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  if (isLoading) {
    return <div>Loading applications...</div>;
  }

  return (
    <ScrollArea className="h-[600px] rounded-md border p-4">
      <div className="space-y-4">
        <h2 className="text-2xl font-bold mb-4">Application Reviews</h2>
        {applications.map((application) => (
          <Card key={application.id} className="p-4">
            <div className="space-y-4">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-lg font-semibold">
                    {application.profiles?.username || 'Unknown User'}
                  </h3>
                  <p className="text-sm text-muted-foreground">
                    {application.profiles?.first_name} {application.profiles?.last_name}
                  </p>
                </div>
                <Badge variant={
                  application.status === 'approved' ? 'default' :
                  application.status === 'rejected' ? 'destructive' : 
                  'secondary'
                }>
                  {application.status}
                </Badge>
              </div>
              
              {application.status === 'pending' && (
                <ApplicationReview
                  application={application}
                  onReviewComplete={fetchApplications}
                />
              )}
            </div>
          </Card>
        ))}
      </div>
    </ScrollArea>
  );
}
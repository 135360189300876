import { SupabaseClient } from "@supabase/supabase-js";
import { NavigateFunction } from "react-router-dom";

interface AuthActionProps {
  navigate: NavigateFunction;
  toast: any;
  supabase: SupabaseClient;
}

export const handleAuthAction = ({ navigate, toast, supabase }: AuthActionProps) => {
  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      toast({
        title: "Signed out successfully",
        description: "You have been signed out of your account",
      });
      navigate("/");
    } catch (error) {
      console.error("[Navigation] Error signing out:", error);
      toast({
        title: "Error signing out",
        description: "There was a problem signing out. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleSignIn = () => {
    navigate("/login", { replace: true });
  };

  return { handleSignOut, handleSignIn };
};
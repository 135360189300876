import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Pencil, Save } from "lucide-react";
import { ProfileHeader } from "./profile/ProfileHeader";
import { ProfileForm } from "./profile/ProfileForm";

interface Profile {
  username: string;
  first_name: string | null;
  last_name: string | null;
  bio: string | null;
  avatar_url: string | null;
}

export const ProfileSection = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from('profiles')
        .select('username, first_name, last_name, bio, avatar_url')
        .eq('id', user.id)
        .single();

      if (error) throw error;
      setProfile(data);
      console.log("Fetched profile:", data);
    } catch (error) {
      console.error('Error fetching profile:', error);
      toast({
        title: "Error",
        description: "Failed to load profile information",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user || !profile) return;

      const { error } = await supabase
        .from('profiles')
        .update({
          first_name: profile.first_name,
          last_name: profile.last_name,
          bio: profile.bio,
        })
        .eq('id', user.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Profile updated successfully",
      });
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      toast({
        title: "Error",
        description: "Failed to update profile",
        variant: "destructive",
      });
    }
  };

  if (loading) {
    return <div className="bg-zinc-900 p-6 rounded-lg">Loading profile...</div>;
  }

  if (!profile) {
    return <div className="bg-zinc-900 p-6 rounded-lg">Profile not found</div>;
  }

  return (
    <div className="bg-zinc-900 p-6 rounded-lg space-y-4">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-semibold text-white">Profile Information</h2>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => isEditing ? handleSave() : setIsEditing(true)}
          className="text-white hover:text-white/80"
        >
          {isEditing ? (
            <Save className="h-5 w-5" />
          ) : (
            <Pencil className="h-5 w-5" />
          )}
        </Button>
      </div>

      <ProfileHeader
        username={profile.username}
        firstName={profile.first_name}
        lastName={profile.last_name}
        avatarUrl={profile.avatar_url}
      />

      <ProfileForm
        isEditing={isEditing}
        profile={profile}
        onProfileChange={(updates) => setProfile({ ...profile, ...updates })}
      />
    </div>
  );
};
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/integrations/supabase/client";
import { Logo } from "./Logo";
import { NavigationItems } from "./navigation/NavigationItems";
import { MobileNavigation } from "./navigation/MobileNavigation";

interface NavigationProps {
  notifications: any[];
  rewards: {
    points: number;
    badges: string[];
  };
}

export const Navigation = ({ notifications, rewards }: NavigationProps) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { user, session } = useAuth();

  const handleSignOut = async () => {
    try {
      console.log("[Navigation] Initiating sign out", { hasSession: !!session });
      
      // If no session, just redirect to login
      if (!session) {
        console.log("[Navigation] No active session found, redirecting to login");
        navigate('/login', { replace: true });
        return;
      }

      const { error } = await supabase.auth.signOut();
      
      if (error) {
        if (error.message.includes('session_not_found')) {
          console.log("[Navigation] Session not found, redirecting to login");
          navigate('/login', { replace: true });
          return;
        }
        throw error;
      }
      
      console.log("[Navigation] Sign out successful");
      toast({
        title: "Signed out successfully",
        description: "You have been signed out of your account",
      });
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('[Navigation] Error signing out:', error);
      // If any error occurs, still redirect to login for safety
      navigate('/login', { replace: true });
      toast({
        title: "Notice",
        description: "You have been signed out.",
      });
    }
  };

  const handleSignIn = () => {
    navigate("/login", { replace: true });
  };

  return (
    <nav className="flex justify-between items-center p-4 md:p-6 bg-background border-b border-border">
      <div className="flex-1 flex justify-start items-center gap-6">
        <Link 
          to="/about"
          className="text-foreground text-lg hidden md:block font-khinterference hover:text-foreground/80"
        >
          Creators
        </Link>
      </div>
      
      <div className="flex-1 flex justify-center items-center">
        <Logo />
      </div>

      <div className="flex-1 hidden md:flex justify-end items-center gap-2 md:gap-4">
        <NavigationItems
          notifications={notifications}
          rewards={rewards}
          isAuthenticated={!!user}
          onSignOut={handleSignOut}
          onSignIn={handleSignIn}
        />
      </div>

      <MobileNavigation
        notifications={notifications}
        rewards={rewards}
        isAuthenticated={!!user}
        onSignOut={handleSignOut}
        onSignIn={handleSignIn}
      />
    </nav>
  );
};
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

interface ProfileFormProps {
  isEditing: boolean;
  profile: {
    first_name: string | null;
    last_name: string | null;
    bio: string | null;
  };
  onProfileChange: (updates: Partial<ProfileFormProps['profile']>) => void;
}

export const ProfileForm = ({ isEditing, profile, onProfileChange }: ProfileFormProps) => {
  return (
    <div className="space-y-4 pt-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-zinc-400 mb-1">
            First Name
          </label>
          {isEditing ? (
            <Input
              value={profile.first_name || ""}
              onChange={(e) => onProfileChange({ first_name: e.target.value })}
              className="bg-zinc-800 border-zinc-700 text-white"
              placeholder="Enter your first name"
            />
          ) : (
            <p className="text-white">{profile.first_name || "Not set"}</p>
          )}
        </div>
        <div>
          <label className="block text-sm font-medium text-zinc-400 mb-1">
            Last Name
          </label>
          {isEditing ? (
            <Input
              value={profile.last_name || ""}
              onChange={(e) => onProfileChange({ last_name: e.target.value })}
              className="bg-zinc-800 border-zinc-700 text-white"
              placeholder="Enter your last name"
            />
          ) : (
            <p className="text-white">{profile.last_name || "Not set"}</p>
          )}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-zinc-400 mb-1">
          Bio
        </label>
        {isEditing ? (
          <Textarea
            value={profile.bio || ""}
            onChange={(e) => onProfileChange({ bio: e.target.value })}
            className="bg-zinc-800 border-zinc-700 text-white"
            placeholder="Tell us about yourself"
          />
        ) : (
          <p className="text-white">{profile.bio || "No bio added yet"}</p>
        )}
      </div>
    </div>
  );
};
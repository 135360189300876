import { ProfileSection } from "@/components/ProfileSection";
import { DashboardHeader } from "@/components/DashboardHeader";
import { Toaster } from "@/components/ui/toaster";

export default function ProfilePage() {
  return (
    <div className="min-h-screen bg-black">
      <DashboardHeader notifications={[]} queuePosition={0} showProfileButton={false} />
      <div className="p-8">
        <div className="max-w-4xl mx-auto">
          <ProfileSection />
        </div>
      </div>
      <Toaster />
    </div>
  );
}
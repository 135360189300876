import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";

interface ApplicationReviewProps {
  application: {
    id: string;
    user_id: string;
    status: 'pending' | 'approved' | 'rejected';
    application_data: any;
    notes?: string;
  };
  onReviewComplete: () => void;
}

export function ApplicationReview({ application, onReviewComplete }: ApplicationReviewProps) {
  const [notes, setNotes] = useState(application.notes || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  const handleReview = async (newStatus: 'approved' | 'rejected') => {
    try {
      setIsSubmitting(true);
      console.log('[ApplicationReview] Updating application status:', { 
        applicationId: application.id, 
        newStatus, 
        notes 
      });

      const { error } = await supabase
        .from('applications')
        .update({
          status: newStatus,
          notes: notes,
          reviewed_at: new Date().toISOString(),
          reviewed_by: (await supabase.auth.getUser()).data.user?.id
        })
        .eq('id', application.id);

      if (error) throw error;

      toast({
        title: "Application Review Complete",
        description: `Application has been ${newStatus}`,
      });

      onReviewComplete();
    } catch (error) {
      console.error('[ApplicationReview] Error updating application:', error);
      toast({
        title: "Error",
        description: "Failed to update application status",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="space-y-4 p-4 bg-card rounded-lg border">
      <div className="space-y-2">
        <h3 className="text-lg font-semibold">Application Review</h3>
        <div className="space-y-4">
          <div>
            <label className="text-sm font-medium">Review Notes</label>
            <Textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Enter review notes..."
              className="mt-1"
            />
          </div>
          <div className="flex space-x-2">
            <Button
              onClick={() => handleReview('approved')}
              disabled={isSubmitting}
              className="bg-green-600 hover:bg-green-700"
            >
              Approve
            </Button>
            <Button
              onClick={() => handleReview('rejected')}
              disabled={isSubmitting}
              variant="destructive"
            >
              Reject
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
import { useEffect, useState } from "react";
import { toast, dispatch } from "./toast/toast-store";
import type { State } from "./toast/toast-types";
import { memoryState } from "./toast/toast-store";

export function useToast() {
  const [state, setState] = useState<State>(memoryState);

  useEffect(() => {
    const listeners: Array<(state: State) => void> = [];
    listeners.push(setState);
    return () => {
      const index = listeners.indexOf(setState);
      if (index > -1) {
        listeners.splice(index, 1);
      }
    };
  }, [state]);

  return {
    ...state,
    toast,
    dismiss: (toastId?: string) => dispatch({ type: "DISMISS_TOAST", toastId }),
  };
}

export { toast };
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";

interface AdminGuardProps {
  children: React.ReactNode;
}

export const AdminGuard = ({ children }: AdminGuardProps) => {
  const { user, isLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { toast } = useToast();

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!user) {
        console.log("[AdminGuard] No user found, redirecting to login");
        toast({
          title: "Authentication Required",
          description: "Please sign in to access this page.",
          variant: "destructive",
        });
        navigate("/login", { 
          replace: true,
          state: { from: location.pathname }
        });
        return;
      }

      try {
        console.log("[AdminGuard] Checking admin status for user:", user.id);
        const { data: adminUser, error } = await supabase
          .from('admin_users')
          .select('id, role, active')
          .eq('id', user.id)
          .maybeSingle();

        console.log("[AdminGuard] Admin check result:", { adminUser, error });

        if (error) {
          throw error;
        }

        if (!adminUser || !adminUser.active) {
          console.log("[AdminGuard] User is not an admin, redirecting to dashboard");
          toast({
            title: "Access Denied",
            description: "You don't have permission to access this area.",
            variant: "destructive",
          });
          navigate("/app/dashboard");
          return;
        }

        console.log("[AdminGuard] Admin access granted", { role: adminUser.role });
      } catch (error) {
        console.error("[AdminGuard] Error checking admin status:", error);
        navigate("/app/dashboard");
      }
    };

    if (!isLoading) {
      checkAdminStatus();
    }
  }, [user, isLoading, navigate, location, toast]);

  // Show loading state
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-pulse text-foreground">Loading...</div>
      </div>
    );
  }

  // If we made it here, the user is an admin
  return <>{children}</>;
};

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { DashboardHeader } from "@/components/DashboardHeader";
import { Toaster } from "@/components/ui/toaster";
import { AdminImpersonationBanner } from "@/components/admin/AdminImpersonationBanner";
import { useAuth } from "@/contexts/AuthContext";
import { DashboardError } from "@/components/dashboard/DashboardError";
import { useDashboardData } from "@/hooks/useDashboardData";
import { DashboardLoader } from "@/components/dashboard/DashboardLoader";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Users, Clock, Bell } from "lucide-react";
import { ProfileSection } from "@/components/ProfileSection";

export default function WaitlistDashboard() {
  const navigate = useNavigate();
  const { user, session, isLoading: authLoading } = useAuth();
  
  const {
    profile,
    queuePosition,
    totalCount,
    notifications,
    isLoading,
    error
  } = useDashboardData(user?.id);

  useEffect(() => {
    if (authLoading) return;

    if (!session || !user) {
      console.log("[WaitlistDashboard] No valid session or user found, redirecting to login");
      navigate('/login');
      return;
    }

    // Check if user is an admin or owner and redirect if necessary
    const checkAdminStatus = async () => {
      try {
        console.log("[WaitlistDashboard] Checking admin status for user:", user.id);
        
        const { data: adminData, error: adminError } = await supabase
          .from('admin_users')
          .select('role, active')
          .eq('id', user.id)
          .maybeSingle();

        console.log("[WaitlistDashboard] Admin check result:", { data: adminData, error: adminError });

        if (adminData?.active && (adminData.role === 'admin' || adminData.role === 'owner')) {
          console.log("[WaitlistDashboard] Admin/Owner user detected, redirecting to admin dashboard");
          navigate('/app/admin/dashboard', { replace: true });
          return;
        }
      } catch (error) {
        console.error("[WaitlistDashboard] Error checking admin status:", error);
      }
    };

    checkAdminStatus();
  }, [navigate, user, session, authLoading]);

  if (authLoading || isLoading) {
    return <DashboardLoader />;
  }

  if (error) {
    return <DashboardError message={error} />;
  }

  return (
    <div className="min-h-screen bg-background text-foreground font-khtekatrial">
      <DashboardHeader 
        notifications={notifications || []}
        queuePosition={queuePosition || 0}
      />
      <main className="p-4 md:p-8">
        <div className="max-w-7xl mx-auto space-y-8">
          {/* Welcome Section */}
          <div className="space-y-2">
            <h1 className="text-4xl font-bold tracking-tight font-khinterferencetrial">
              Welcome{profile?.username ? `, ${profile.username}` : ''}! 👋
            </h1>
            <p className="text-muted-foreground">
              Here's your current status and activity on the waitlist.
            </p>
          </div>

          {/* Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Queue Position Card */}
            <Card className="bg-zinc-900 border-zinc-800">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">Queue Position</CardTitle>
                <Clock className="h-4 w-4 text-muted-foreground" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">#{queuePosition || '...'}</div>
                <p className="text-xs text-muted-foreground">
                  {totalCount ? `out of ${totalCount} in queue` : 'in the waitlist queue'}
                </p>
              </CardContent>
            </Card>

            {/* Available Invites Card */}
            <Card className="bg-zinc-900 border-zinc-800">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">Available Invites</CardTitle>
                <Users className="h-4 w-4 text-muted-foreground" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{profile?.available_invites || 0}</div>
                <p className="text-xs text-muted-foreground">
                  invites remaining
                </p>
              </CardContent>
            </Card>

            {/* Notifications Card */}
            <Card className="bg-zinc-900 border-zinc-800">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">New Notifications</CardTitle>
                <Bell className="h-4 w-4 text-muted-foreground" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">
                  {notifications?.filter(n => !n.read).length || 0}
                </div>
                <p className="text-xs text-muted-foreground">
                  unread notifications
                </p>
              </CardContent>
            </Card>
          </div>

          {/* Profile Section */}
          {profile && (
            <div className="space-y-6">
              {/* Profile Completion Card */}
              <Card className="bg-zinc-900 border-zinc-800">
                <CardHeader>
                  <CardTitle>Profile Completion</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="space-y-2">
                    <div className="flex justify-between text-sm">
                      <span>Progress</span>
                      <span>{profile.profile_completion_percentage || 0}%</span>
                    </div>
                    <div className="h-2 bg-zinc-800 rounded-full overflow-hidden">
                      <div 
                        className="h-full bg-white transition-all duration-300 ease-in-out"
                        style={{ width: `${profile.profile_completion_percentage || 0}%` }}
                      />
                    </div>
                    {(!profile.profile_completion_percentage || profile.profile_completion_percentage < 100) && (
                      <p className="text-sm text-muted-foreground mt-2">
                        Complete your profile to increase your chances of getting off the waitlist faster!
                      </p>
                    )}
                  </div>
                </CardContent>
              </Card>

              {/* Profile Information */}
              <ProfileSection />
            </div>
          )}
        </div>
      </main>
      <Toaster />
      <AdminImpersonationBanner />
    </div>
  );
}

import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { useAuth } from "@/contexts/AuthContext";
import { supabase } from "@/integrations/supabase/client";
import { Logo } from "../Logo";
import { NavigationItems } from "./NavigationItems";
import { Link } from "react-router-dom";
import { MobileNavigation } from "./MobileNavigation";
import { handleAuthAction } from "@/utils/navigationUtils";

interface NavigationProps {
  notifications: any[];
  rewards: {
    points: number;
    badges: string[];
  };
}

export const NavigationContainer = ({ notifications, rewards }: NavigationProps) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { user } = useAuth();

  const { handleSignOut, handleSignIn } = handleAuthAction({ navigate, toast, supabase });

  return (
    <nav className="flex justify-between items-center p-4 md:p-6 bg-background border-b border-border">
      <div className="flex-1 flex justify-start items-center gap-6">
        <Link 
          to="/about"
          className="text-foreground text-lg hidden md:block font-khinterference hover:text-foreground/80"
        >
          Creators
        </Link>
      </div>
      
      <div className="flex-1 flex justify-center items-center">
        <Logo />
      </div>

      <div className="flex-1 hidden md:flex justify-end items-center gap-2 md:gap-4">
        <NavigationItems
          notifications={notifications}
          rewards={rewards}
          isAuthenticated={!!user}
          onSignOut={handleSignOut}
          onSignIn={handleSignIn}
        />
      </div>

      <MobileNavigation
        notifications={notifications}
        rewards={rewards}
        isAuthenticated={!!user}
        onSignOut={handleSignOut}
        onSignIn={handleSignIn}
      />
    </nav>
  );
};
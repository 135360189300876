import { Input } from "@/components/ui/input";
import { FieldError, UseFormRegister } from "react-hook-form";
import { InviteFormData } from "@/types/auth";

interface FormFieldProps {
  name: keyof InviteFormData;
  type: string;
  placeholder: string;
  register: UseFormRegister<InviteFormData>;
  error?: FieldError;
  validation?: object;
}

export const FormField = ({
  name,
  type,
  placeholder,
  register,
  error,
  validation
}: FormFieldProps) => {
  return (
    <div>
      <Input
        {...register(name, validation)}
        type={type}
        placeholder={placeholder}
        className="w-full bg-zinc-800 border-zinc-700 text-white"
      />
      {error && (
        <p className="text-red-500 text-sm mt-1">{error.message}</p>
      )}
    </div>
  );
};